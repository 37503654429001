/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiCommentChangeStatusPut } from '../fn/comment/api-comment-change-status-put';
import { ApiCommentChangeStatusPut$Params } from '../fn/comment/api-comment-change-status-put';
import { apiCommentGetAllCommentsForAdminGet } from '../fn/comment/api-comment-get-all-comments-for-admin-get';
import { ApiCommentGetAllCommentsForAdminGet$Params } from '../fn/comment/api-comment-get-all-comments-for-admin-get';
import { apiCommentGetAllCommentsForAdminGet$Plain } from '../fn/comment/api-comment-get-all-comments-for-admin-get-plain';
import { ApiCommentGetAllCommentsForAdminGet$Plain$Params } from '../fn/comment/api-comment-get-all-comments-for-admin-get-plain';
import { apiCommentGetAllCommentsGet } from '../fn/comment/api-comment-get-all-comments-get';
import { ApiCommentGetAllCommentsGet$Params } from '../fn/comment/api-comment-get-all-comments-get';
import { apiCommentGetAllCommentsGet$Plain } from '../fn/comment/api-comment-get-all-comments-get-plain';
import { ApiCommentGetAllCommentsGet$Plain$Params } from '../fn/comment/api-comment-get-all-comments-get-plain';
import { apiCommentGetPinnedCommentsForAdminGet } from '../fn/comment/api-comment-get-pinned-comments-for-admin-get';
import { ApiCommentGetPinnedCommentsForAdminGet$Params } from '../fn/comment/api-comment-get-pinned-comments-for-admin-get';
import { apiCommentGetPinnedCommentsForAdminGet$Plain } from '../fn/comment/api-comment-get-pinned-comments-for-admin-get-plain';
import { ApiCommentGetPinnedCommentsForAdminGet$Plain$Params } from '../fn/comment/api-comment-get-pinned-comments-for-admin-get-plain';
import { apiCommentIdDelete } from '../fn/comment/api-comment-id-delete';
import { ApiCommentIdDelete$Params } from '../fn/comment/api-comment-id-delete';
import { apiCommentIdGet } from '../fn/comment/api-comment-id-get';
import { ApiCommentIdGet$Params } from '../fn/comment/api-comment-id-get';
import { apiCommentIdGet$Plain } from '../fn/comment/api-comment-id-get-plain';
import { ApiCommentIdGet$Plain$Params } from '../fn/comment/api-comment-id-get-plain';
import { apiCommentIdPut } from '../fn/comment/api-comment-id-put';
import { ApiCommentIdPut$Params } from '../fn/comment/api-comment-id-put';
import { apiCommentPost } from '../fn/comment/api-comment-post';
import { ApiCommentPost$Params } from '../fn/comment/api-comment-post';
import { ViewCommentDto } from '../models/view-comment-dto';
import { ViewCommentDtoIEnumerablePagination } from '../models/view-comment-dto-i-enumerable-pagination';

@Injectable({ providedIn: 'root' })
export class CommentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiCommentGetAllCommentsGet()` */
  static readonly ApiCommentGetAllCommentsGetPath = '/api/Comment/getAllComments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentGetAllCommentsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentGetAllCommentsGet$Plain$Response(params?: ApiCommentGetAllCommentsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewCommentDtoIEnumerablePagination>> {
    return apiCommentGetAllCommentsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCommentGetAllCommentsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentGetAllCommentsGet$Plain(params?: ApiCommentGetAllCommentsGet$Plain$Params, context?: HttpContext): Observable<ViewCommentDtoIEnumerablePagination> {
    return this.apiCommentGetAllCommentsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewCommentDtoIEnumerablePagination>): ViewCommentDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentGetAllCommentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentGetAllCommentsGet$Response(params?: ApiCommentGetAllCommentsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewCommentDtoIEnumerablePagination>> {
    return apiCommentGetAllCommentsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCommentGetAllCommentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentGetAllCommentsGet(params?: ApiCommentGetAllCommentsGet$Params, context?: HttpContext): Observable<ViewCommentDtoIEnumerablePagination> {
    return this.apiCommentGetAllCommentsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewCommentDtoIEnumerablePagination>): ViewCommentDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiCommentGetAllCommentsForAdminGet()` */
  static readonly ApiCommentGetAllCommentsForAdminGetPath = '/api/Comment/getAllCommentsForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentGetAllCommentsForAdminGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentGetAllCommentsForAdminGet$Plain$Response(params?: ApiCommentGetAllCommentsForAdminGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewCommentDtoIEnumerablePagination>> {
    return apiCommentGetAllCommentsForAdminGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCommentGetAllCommentsForAdminGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentGetAllCommentsForAdminGet$Plain(params?: ApiCommentGetAllCommentsForAdminGet$Plain$Params, context?: HttpContext): Observable<ViewCommentDtoIEnumerablePagination> {
    return this.apiCommentGetAllCommentsForAdminGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewCommentDtoIEnumerablePagination>): ViewCommentDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentGetAllCommentsForAdminGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentGetAllCommentsForAdminGet$Response(params?: ApiCommentGetAllCommentsForAdminGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewCommentDtoIEnumerablePagination>> {
    return apiCommentGetAllCommentsForAdminGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCommentGetAllCommentsForAdminGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentGetAllCommentsForAdminGet(params?: ApiCommentGetAllCommentsForAdminGet$Params, context?: HttpContext): Observable<ViewCommentDtoIEnumerablePagination> {
    return this.apiCommentGetAllCommentsForAdminGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewCommentDtoIEnumerablePagination>): ViewCommentDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiCommentGetPinnedCommentsForAdminGet()` */
  static readonly ApiCommentGetPinnedCommentsForAdminGetPath = '/api/Comment/getPinnedCommentsForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentGetPinnedCommentsForAdminGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentGetPinnedCommentsForAdminGet$Plain$Response(params?: ApiCommentGetPinnedCommentsForAdminGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewCommentDtoIEnumerablePagination>> {
    return apiCommentGetPinnedCommentsForAdminGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCommentGetPinnedCommentsForAdminGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentGetPinnedCommentsForAdminGet$Plain(params?: ApiCommentGetPinnedCommentsForAdminGet$Plain$Params, context?: HttpContext): Observable<ViewCommentDtoIEnumerablePagination> {
    return this.apiCommentGetPinnedCommentsForAdminGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewCommentDtoIEnumerablePagination>): ViewCommentDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentGetPinnedCommentsForAdminGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentGetPinnedCommentsForAdminGet$Response(params?: ApiCommentGetPinnedCommentsForAdminGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewCommentDtoIEnumerablePagination>> {
    return apiCommentGetPinnedCommentsForAdminGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCommentGetPinnedCommentsForAdminGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentGetPinnedCommentsForAdminGet(params?: ApiCommentGetPinnedCommentsForAdminGet$Params, context?: HttpContext): Observable<ViewCommentDtoIEnumerablePagination> {
    return this.apiCommentGetPinnedCommentsForAdminGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewCommentDtoIEnumerablePagination>): ViewCommentDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiCommentIdGet()` */
  static readonly ApiCommentIdGetPath = '/api/Comment/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentIdGet$Plain$Response(params: ApiCommentIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewCommentDto>> {
    return apiCommentIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCommentIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentIdGet$Plain(params: ApiCommentIdGet$Plain$Params, context?: HttpContext): Observable<ViewCommentDto> {
    return this.apiCommentIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewCommentDto>): ViewCommentDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentIdGet$Response(params: ApiCommentIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewCommentDto>> {
    return apiCommentIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCommentIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentIdGet(params: ApiCommentIdGet$Params, context?: HttpContext): Observable<ViewCommentDto> {
    return this.apiCommentIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewCommentDto>): ViewCommentDto => r.body)
    );
  }

  /** Path part for operation `apiCommentIdPut()` */
  static readonly ApiCommentIdPutPath = '/api/Comment/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentIdPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCommentIdPut$Response(params: ApiCommentIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiCommentIdPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCommentIdPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCommentIdPut(params: ApiCommentIdPut$Params, context?: HttpContext): Observable<void> {
    return this.apiCommentIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiCommentIdDelete()` */
  static readonly ApiCommentIdDeletePath = '/api/Comment/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentIdDelete$Response(params: ApiCommentIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiCommentIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCommentIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentIdDelete(params: ApiCommentIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiCommentIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiCommentPost()` */
  static readonly ApiCommentPostPath = '/api/Comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCommentPost$Response(params?: ApiCommentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiCommentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCommentPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCommentPost(params?: ApiCommentPost$Params, context?: HttpContext): Observable<void> {
    return this.apiCommentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiCommentChangeStatusPut()` */
  static readonly ApiCommentChangeStatusPutPath = '/api/Comment/ChangeStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentChangeStatusPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentChangeStatusPut$Response(params?: ApiCommentChangeStatusPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiCommentChangeStatusPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCommentChangeStatusPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentChangeStatusPut(params?: ApiCommentChangeStatusPut$Params, context?: HttpContext): Observable<void> {
    return this.apiCommentChangeStatusPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
