/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiChapterChangeStatusPut } from '../fn/chapter/api-chapter-change-status-put';
import { ApiChapterChangeStatusPut$Params } from '../fn/chapter/api-chapter-change-status-put';
import { apiChapterGet } from '../fn/chapter/api-chapter-get';
import { ApiChapterGet$Params } from '../fn/chapter/api-chapter-get';
import { apiChapterGet$Plain } from '../fn/chapter/api-chapter-get-plain';
import { ApiChapterGet$Plain$Params } from '../fn/chapter/api-chapter-get-plain';
import { apiChapterIdDelete } from '../fn/chapter/api-chapter-id-delete';
import { ApiChapterIdDelete$Params } from '../fn/chapter/api-chapter-id-delete';
import { apiChapterIdGet } from '../fn/chapter/api-chapter-id-get';
import { ApiChapterIdGet$Params } from '../fn/chapter/api-chapter-id-get';
import { apiChapterIdGet$Plain } from '../fn/chapter/api-chapter-id-get-plain';
import { ApiChapterIdGet$Plain$Params } from '../fn/chapter/api-chapter-id-get-plain';
import { apiChapterIdPut } from '../fn/chapter/api-chapter-id-put';
import { ApiChapterIdPut$Params } from '../fn/chapter/api-chapter-id-put';
import { apiChapterPost } from '../fn/chapter/api-chapter-post';
import { ApiChapterPost$Params } from '../fn/chapter/api-chapter-post';
import { ViewChapterDto } from '../models/view-chapter-dto';
import { ViewChapterDtoIEnumerablePagination } from '../models/view-chapter-dto-i-enumerable-pagination';

@Injectable({ providedIn: 'root' })
export class ChapterService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiChapterGet()` */
  static readonly ApiChapterGetPath = '/api/Chapter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChapterGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChapterGet$Plain$Response(params?: ApiChapterGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewChapterDtoIEnumerablePagination>> {
    return apiChapterGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiChapterGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChapterGet$Plain(params?: ApiChapterGet$Plain$Params, context?: HttpContext): Observable<ViewChapterDtoIEnumerablePagination> {
    return this.apiChapterGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewChapterDtoIEnumerablePagination>): ViewChapterDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChapterGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChapterGet$Response(params?: ApiChapterGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewChapterDtoIEnumerablePagination>> {
    return apiChapterGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiChapterGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChapterGet(params?: ApiChapterGet$Params, context?: HttpContext): Observable<ViewChapterDtoIEnumerablePagination> {
    return this.apiChapterGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewChapterDtoIEnumerablePagination>): ViewChapterDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiChapterPost()` */
  static readonly ApiChapterPostPath = '/api/Chapter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChapterPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiChapterPost$Response(params?: ApiChapterPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiChapterPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiChapterPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiChapterPost(params?: ApiChapterPost$Params, context?: HttpContext): Observable<void> {
    return this.apiChapterPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiChapterIdGet()` */
  static readonly ApiChapterIdGetPath = '/api/Chapter/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChapterIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChapterIdGet$Plain$Response(params: ApiChapterIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewChapterDto>> {
    return apiChapterIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiChapterIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChapterIdGet$Plain(params: ApiChapterIdGet$Plain$Params, context?: HttpContext): Observable<ViewChapterDto> {
    return this.apiChapterIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewChapterDto>): ViewChapterDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChapterIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChapterIdGet$Response(params: ApiChapterIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewChapterDto>> {
    return apiChapterIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiChapterIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChapterIdGet(params: ApiChapterIdGet$Params, context?: HttpContext): Observable<ViewChapterDto> {
    return this.apiChapterIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewChapterDto>): ViewChapterDto => r.body)
    );
  }

  /** Path part for operation `apiChapterIdPut()` */
  static readonly ApiChapterIdPutPath = '/api/Chapter/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChapterIdPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiChapterIdPut$Response(params: ApiChapterIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiChapterIdPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiChapterIdPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiChapterIdPut(params: ApiChapterIdPut$Params, context?: HttpContext): Observable<void> {
    return this.apiChapterIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiChapterIdDelete()` */
  static readonly ApiChapterIdDeletePath = '/api/Chapter/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChapterIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChapterIdDelete$Response(params: ApiChapterIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiChapterIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiChapterIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChapterIdDelete(params: ApiChapterIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiChapterIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiChapterChangeStatusPut()` */
  static readonly ApiChapterChangeStatusPutPath = '/api/Chapter/ChangeStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChapterChangeStatusPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChapterChangeStatusPut$Response(params?: ApiChapterChangeStatusPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiChapterChangeStatusPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiChapterChangeStatusPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChapterChangeStatusPut(params?: ApiChapterChangeStatusPut$Params, context?: HttpContext): Observable<void> {
    return this.apiChapterChangeStatusPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
