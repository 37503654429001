/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiExamAddExamPost } from '../fn/exam/api-exam-add-exam-post';
import { ApiExamAddExamPost$Params } from '../fn/exam/api-exam-add-exam-post';
import { apiExamChangeStatusPut } from '../fn/exam/api-exam-change-status-put';
import { ApiExamChangeStatusPut$Params } from '../fn/exam/api-exam-change-status-put';
import { apiExamDeleteExamIdDelete } from '../fn/exam/api-exam-delete-exam-id-delete';
import { ApiExamDeleteExamIdDelete$Params } from '../fn/exam/api-exam-delete-exam-id-delete';
import { apiExamGetAllExamsGet } from '../fn/exam/api-exam-get-all-exams-get';
import { ApiExamGetAllExamsGet$Params } from '../fn/exam/api-exam-get-all-exams-get';
import { apiExamGetAllExamsGet$Plain } from '../fn/exam/api-exam-get-all-exams-get-plain';
import { ApiExamGetAllExamsGet$Plain$Params } from '../fn/exam/api-exam-get-all-exams-get-plain';
import { apiExamGetExamByIdIdGet } from '../fn/exam/api-exam-get-exam-by-id-id-get';
import { ApiExamGetExamByIdIdGet$Params } from '../fn/exam/api-exam-get-exam-by-id-id-get';
import { apiExamGetExamByIdIdGet$Plain } from '../fn/exam/api-exam-get-exam-by-id-id-get-plain';
import { ApiExamGetExamByIdIdGet$Plain$Params } from '../fn/exam/api-exam-get-exam-by-id-id-get-plain';
import { apiExamGetExamQuesionsForEditGet } from '../fn/exam/api-exam-get-exam-quesions-for-edit-get';
import { ApiExamGetExamQuesionsForEditGet$Params } from '../fn/exam/api-exam-get-exam-quesions-for-edit-get';
import { apiExamGetExamQuesionsForEditGet$Plain } from '../fn/exam/api-exam-get-exam-quesions-for-edit-get-plain';
import { ApiExamGetExamQuesionsForEditGet$Plain$Params } from '../fn/exam/api-exam-get-exam-quesions-for-edit-get-plain';
import { apiExamGetExamQuesionsForStudentGet } from '../fn/exam/api-exam-get-exam-quesions-for-student-get';
import { ApiExamGetExamQuesionsForStudentGet$Params } from '../fn/exam/api-exam-get-exam-quesions-for-student-get';
import { apiExamGetExamQuesionsForStudentGet$Plain } from '../fn/exam/api-exam-get-exam-quesions-for-student-get-plain';
import { ApiExamGetExamQuesionsForStudentGet$Plain$Params } from '../fn/exam/api-exam-get-exam-quesions-for-student-get-plain';
import { apiExamUpdateExamPut } from '../fn/exam/api-exam-update-exam-put';
import { ApiExamUpdateExamPut$Params } from '../fn/exam/api-exam-update-exam-put';
import { ViewExamDto } from '../models/view-exam-dto';
import { ViewExamDtoIEnumerablePagination } from '../models/view-exam-dto-i-enumerable-pagination';
import { ViewQuestionDto } from '../models/view-question-dto';

@Injectable({ providedIn: 'root' })
export class ExamService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiExamGetAllExamsGet()` */
  static readonly ApiExamGetAllExamsGetPath = '/api/Exam/getAllExams';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExamGetAllExamsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExamGetAllExamsGet$Plain$Response(params?: ApiExamGetAllExamsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewExamDtoIEnumerablePagination>> {
    return apiExamGetAllExamsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiExamGetAllExamsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExamGetAllExamsGet$Plain(params?: ApiExamGetAllExamsGet$Plain$Params, context?: HttpContext): Observable<ViewExamDtoIEnumerablePagination> {
    return this.apiExamGetAllExamsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewExamDtoIEnumerablePagination>): ViewExamDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExamGetAllExamsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExamGetAllExamsGet$Response(params?: ApiExamGetAllExamsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewExamDtoIEnumerablePagination>> {
    return apiExamGetAllExamsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiExamGetAllExamsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExamGetAllExamsGet(params?: ApiExamGetAllExamsGet$Params, context?: HttpContext): Observable<ViewExamDtoIEnumerablePagination> {
    return this.apiExamGetAllExamsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewExamDtoIEnumerablePagination>): ViewExamDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiExamGetExamByIdIdGet()` */
  static readonly ApiExamGetExamByIdIdGetPath = '/api/Exam/getExamById/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExamGetExamByIdIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExamGetExamByIdIdGet$Plain$Response(params: ApiExamGetExamByIdIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewExamDto>> {
    return apiExamGetExamByIdIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiExamGetExamByIdIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExamGetExamByIdIdGet$Plain(params: ApiExamGetExamByIdIdGet$Plain$Params, context?: HttpContext): Observable<ViewExamDto> {
    return this.apiExamGetExamByIdIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewExamDto>): ViewExamDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExamGetExamByIdIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExamGetExamByIdIdGet$Response(params: ApiExamGetExamByIdIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewExamDto>> {
    return apiExamGetExamByIdIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiExamGetExamByIdIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExamGetExamByIdIdGet(params: ApiExamGetExamByIdIdGet$Params, context?: HttpContext): Observable<ViewExamDto> {
    return this.apiExamGetExamByIdIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewExamDto>): ViewExamDto => r.body)
    );
  }

  /** Path part for operation `apiExamGetExamQuesionsForStudentGet()` */
  static readonly ApiExamGetExamQuesionsForStudentGetPath = '/api/Exam/GetExamQuesionsForStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExamGetExamQuesionsForStudentGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExamGetExamQuesionsForStudentGet$Plain$Response(params?: ApiExamGetExamQuesionsForStudentGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewQuestionDto>>> {
    return apiExamGetExamQuesionsForStudentGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiExamGetExamQuesionsForStudentGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExamGetExamQuesionsForStudentGet$Plain(params?: ApiExamGetExamQuesionsForStudentGet$Plain$Params, context?: HttpContext): Observable<Array<ViewQuestionDto>> {
    return this.apiExamGetExamQuesionsForStudentGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewQuestionDto>>): Array<ViewQuestionDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExamGetExamQuesionsForStudentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExamGetExamQuesionsForStudentGet$Response(params?: ApiExamGetExamQuesionsForStudentGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewQuestionDto>>> {
    return apiExamGetExamQuesionsForStudentGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiExamGetExamQuesionsForStudentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExamGetExamQuesionsForStudentGet(params?: ApiExamGetExamQuesionsForStudentGet$Params, context?: HttpContext): Observable<Array<ViewQuestionDto>> {
    return this.apiExamGetExamQuesionsForStudentGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewQuestionDto>>): Array<ViewQuestionDto> => r.body)
    );
  }

  /** Path part for operation `apiExamGetExamQuesionsForEditGet()` */
  static readonly ApiExamGetExamQuesionsForEditGetPath = '/api/Exam/GetExamQuesionsForEdit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExamGetExamQuesionsForEditGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExamGetExamQuesionsForEditGet$Plain$Response(params?: ApiExamGetExamQuesionsForEditGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewQuestionDto>>> {
    return apiExamGetExamQuesionsForEditGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiExamGetExamQuesionsForEditGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExamGetExamQuesionsForEditGet$Plain(params?: ApiExamGetExamQuesionsForEditGet$Plain$Params, context?: HttpContext): Observable<Array<ViewQuestionDto>> {
    return this.apiExamGetExamQuesionsForEditGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewQuestionDto>>): Array<ViewQuestionDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExamGetExamQuesionsForEditGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExamGetExamQuesionsForEditGet$Response(params?: ApiExamGetExamQuesionsForEditGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewQuestionDto>>> {
    return apiExamGetExamQuesionsForEditGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiExamGetExamQuesionsForEditGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExamGetExamQuesionsForEditGet(params?: ApiExamGetExamQuesionsForEditGet$Params, context?: HttpContext): Observable<Array<ViewQuestionDto>> {
    return this.apiExamGetExamQuesionsForEditGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewQuestionDto>>): Array<ViewQuestionDto> => r.body)
    );
  }

  /** Path part for operation `apiExamChangeStatusPut()` */
  static readonly ApiExamChangeStatusPutPath = '/api/Exam/ChangeStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExamChangeStatusPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExamChangeStatusPut$Response(params?: ApiExamChangeStatusPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiExamChangeStatusPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiExamChangeStatusPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExamChangeStatusPut(params?: ApiExamChangeStatusPut$Params, context?: HttpContext): Observable<void> {
    return this.apiExamChangeStatusPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiExamAddExamPost()` */
  static readonly ApiExamAddExamPostPath = '/api/Exam/addExam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExamAddExamPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiExamAddExamPost$Response(params?: ApiExamAddExamPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiExamAddExamPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiExamAddExamPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiExamAddExamPost(params?: ApiExamAddExamPost$Params, context?: HttpContext): Observable<void> {
    return this.apiExamAddExamPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiExamUpdateExamPut()` */
  static readonly ApiExamUpdateExamPutPath = '/api/Exam/updateExam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExamUpdateExamPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiExamUpdateExamPut$Response(params?: ApiExamUpdateExamPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiExamUpdateExamPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiExamUpdateExamPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiExamUpdateExamPut(params?: ApiExamUpdateExamPut$Params, context?: HttpContext): Observable<void> {
    return this.apiExamUpdateExamPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiExamDeleteExamIdDelete()` */
  static readonly ApiExamDeleteExamIdDeletePath = '/api/Exam/deleteExam/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExamDeleteExamIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExamDeleteExamIdDelete$Response(params: ApiExamDeleteExamIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiExamDeleteExamIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiExamDeleteExamIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExamDeleteExamIdDelete(params: ApiExamDeleteExamIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiExamDeleteExamIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
