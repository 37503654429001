/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiToDoListChangeStatusPut } from '../fn/to-do-list/api-to-do-list-change-status-put';
import { ApiToDoListChangeStatusPut$Params } from '../fn/to-do-list/api-to-do-list-change-status-put';
import { apiToDoListGet } from '../fn/to-do-list/api-to-do-list-get';
import { ApiToDoListGet$Params } from '../fn/to-do-list/api-to-do-list-get';
import { apiToDoListGet$Plain } from '../fn/to-do-list/api-to-do-list-get-plain';
import { ApiToDoListGet$Plain$Params } from '../fn/to-do-list/api-to-do-list-get-plain';
import { apiToDoListIdDelete } from '../fn/to-do-list/api-to-do-list-id-delete';
import { ApiToDoListIdDelete$Params } from '../fn/to-do-list/api-to-do-list-id-delete';
import { apiToDoListIdGet } from '../fn/to-do-list/api-to-do-list-id-get';
import { ApiToDoListIdGet$Params } from '../fn/to-do-list/api-to-do-list-id-get';
import { apiToDoListIdGet$Plain } from '../fn/to-do-list/api-to-do-list-id-get-plain';
import { ApiToDoListIdGet$Plain$Params } from '../fn/to-do-list/api-to-do-list-id-get-plain';
import { apiToDoListIdPut } from '../fn/to-do-list/api-to-do-list-id-put';
import { ApiToDoListIdPut$Params } from '../fn/to-do-list/api-to-do-list-id-put';
import { apiToDoListPost } from '../fn/to-do-list/api-to-do-list-post';
import { ApiToDoListPost$Params } from '../fn/to-do-list/api-to-do-list-post';
import { ViewToDoListDto } from '../models/view-to-do-list-dto';
import { ViewToDoListDtoIEnumerablePagination } from '../models/view-to-do-list-dto-i-enumerable-pagination';

@Injectable({ providedIn: 'root' })
export class ToDoListService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiToDoListGet()` */
  static readonly ApiToDoListGetPath = '/api/ToDoList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiToDoListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoListGet$Plain$Response(params?: ApiToDoListGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewToDoListDtoIEnumerablePagination>> {
    return apiToDoListGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiToDoListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoListGet$Plain(params?: ApiToDoListGet$Plain$Params, context?: HttpContext): Observable<ViewToDoListDtoIEnumerablePagination> {
    return this.apiToDoListGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewToDoListDtoIEnumerablePagination>): ViewToDoListDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiToDoListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoListGet$Response(params?: ApiToDoListGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewToDoListDtoIEnumerablePagination>> {
    return apiToDoListGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiToDoListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoListGet(params?: ApiToDoListGet$Params, context?: HttpContext): Observable<ViewToDoListDtoIEnumerablePagination> {
    return this.apiToDoListGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewToDoListDtoIEnumerablePagination>): ViewToDoListDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiToDoListPost()` */
  static readonly ApiToDoListPostPath = '/api/ToDoList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiToDoListPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiToDoListPost$Response(params?: ApiToDoListPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiToDoListPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiToDoListPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiToDoListPost(params?: ApiToDoListPost$Params, context?: HttpContext): Observable<void> {
    return this.apiToDoListPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiToDoListIdGet()` */
  static readonly ApiToDoListIdGetPath = '/api/ToDoList/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiToDoListIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoListIdGet$Plain$Response(params: ApiToDoListIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewToDoListDto>> {
    return apiToDoListIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiToDoListIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoListIdGet$Plain(params: ApiToDoListIdGet$Plain$Params, context?: HttpContext): Observable<ViewToDoListDto> {
    return this.apiToDoListIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewToDoListDto>): ViewToDoListDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiToDoListIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoListIdGet$Response(params: ApiToDoListIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewToDoListDto>> {
    return apiToDoListIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiToDoListIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoListIdGet(params: ApiToDoListIdGet$Params, context?: HttpContext): Observable<ViewToDoListDto> {
    return this.apiToDoListIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewToDoListDto>): ViewToDoListDto => r.body)
    );
  }

  /** Path part for operation `apiToDoListIdPut()` */
  static readonly ApiToDoListIdPutPath = '/api/ToDoList/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiToDoListIdPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiToDoListIdPut$Response(params: ApiToDoListIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiToDoListIdPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiToDoListIdPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiToDoListIdPut(params: ApiToDoListIdPut$Params, context?: HttpContext): Observable<void> {
    return this.apiToDoListIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiToDoListIdDelete()` */
  static readonly ApiToDoListIdDeletePath = '/api/ToDoList/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiToDoListIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoListIdDelete$Response(params: ApiToDoListIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiToDoListIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiToDoListIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoListIdDelete(params: ApiToDoListIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiToDoListIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiToDoListChangeStatusPut()` */
  static readonly ApiToDoListChangeStatusPutPath = '/api/ToDoList/ChangeStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiToDoListChangeStatusPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoListChangeStatusPut$Response(params?: ApiToDoListChangeStatusPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiToDoListChangeStatusPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiToDoListChangeStatusPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoListChangeStatusPut(params?: ApiToDoListChangeStatusPut$Params, context?: HttpContext): Observable<void> {
    return this.apiToDoListChangeStatusPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
