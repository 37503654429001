/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiStudentExamAddStudentExamAnswerPost } from '../fn/student-exam/api-student-exam-add-student-exam-answer-post';
import { ApiStudentExamAddStudentExamAnswerPost$Params } from '../fn/student-exam/api-student-exam-add-student-exam-answer-post';
import { apiStudentExamAddStudentExamAnswerPost$Plain } from '../fn/student-exam/api-student-exam-add-student-exam-answer-post-plain';
import { ApiStudentExamAddStudentExamAnswerPost$Plain$Params } from '../fn/student-exam/api-student-exam-add-student-exam-answer-post-plain';
import { apiStudentExamCorrectArticleAnswerPost } from '../fn/student-exam/api-student-exam-correct-article-answer-post';
import { ApiStudentExamCorrectArticleAnswerPost$Params } from '../fn/student-exam/api-student-exam-correct-article-answer-post';
import { apiStudentExamCorrectArticleAnswerPost$Plain } from '../fn/student-exam/api-student-exam-correct-article-answer-post-plain';
import { ApiStudentExamCorrectArticleAnswerPost$Plain$Params } from '../fn/student-exam/api-student-exam-correct-article-answer-post-plain';
import { apiStudentExamFinishStudentExamGet } from '../fn/student-exam/api-student-exam-finish-student-exam-get';
import { ApiStudentExamFinishStudentExamGet$Params } from '../fn/student-exam/api-student-exam-finish-student-exam-get';
import { apiStudentExamFinishStudentExamGet$Plain } from '../fn/student-exam/api-student-exam-finish-student-exam-get-plain';
import { ApiStudentExamFinishStudentExamGet$Plain$Params } from '../fn/student-exam/api-student-exam-finish-student-exam-get-plain';
import { apiStudentExamGetNewStudentExamsGet } from '../fn/student-exam/api-student-exam-get-new-student-exams-get';
import { ApiStudentExamGetNewStudentExamsGet$Params } from '../fn/student-exam/api-student-exam-get-new-student-exams-get';
import { apiStudentExamGetNewStudentExamsGet$Plain } from '../fn/student-exam/api-student-exam-get-new-student-exams-get-plain';
import { ApiStudentExamGetNewStudentExamsGet$Plain$Params } from '../fn/student-exam/api-student-exam-get-new-student-exams-get-plain';
import { apiStudentExamGetSolvedStudentExamsForStudentGet } from '../fn/student-exam/api-student-exam-get-solved-student-exams-for-student-get';
import { ApiStudentExamGetSolvedStudentExamsForStudentGet$Params } from '../fn/student-exam/api-student-exam-get-solved-student-exams-for-student-get';
import { apiStudentExamGetSolvedStudentExamsForStudentGet$Plain } from '../fn/student-exam/api-student-exam-get-solved-student-exams-for-student-get-plain';
import { ApiStudentExamGetSolvedStudentExamsForStudentGet$Plain$Params } from '../fn/student-exam/api-student-exam-get-solved-student-exams-for-student-get-plain';
import { apiStudentExamGetStudentAnswerForExamGet } from '../fn/student-exam/api-student-exam-get-student-answer-for-exam-get';
import { ApiStudentExamGetStudentAnswerForExamGet$Params } from '../fn/student-exam/api-student-exam-get-student-answer-for-exam-get';
import { apiStudentExamGetStudentAnswerForExamGet$Plain } from '../fn/student-exam/api-student-exam-get-student-answer-for-exam-get-plain';
import { ApiStudentExamGetStudentAnswerForExamGet$Plain$Params } from '../fn/student-exam/api-student-exam-get-student-answer-for-exam-get-plain';
import { apiStudentExamGetStudentAnswerModelForExamGet } from '../fn/student-exam/api-student-exam-get-student-answer-model-for-exam-get';
import { ApiStudentExamGetStudentAnswerModelForExamGet$Params } from '../fn/student-exam/api-student-exam-get-student-answer-model-for-exam-get';
import { apiStudentExamGetStudentAnswerModelForExamGet$Plain } from '../fn/student-exam/api-student-exam-get-student-answer-model-for-exam-get-plain';
import { ApiStudentExamGetStudentAnswerModelForExamGet$Plain$Params } from '../fn/student-exam/api-student-exam-get-student-answer-model-for-exam-get-plain';
import { apiStudentExamGetStudentExamForAdminGet } from '../fn/student-exam/api-student-exam-get-student-exam-for-admin-get';
import { ApiStudentExamGetStudentExamForAdminGet$Params } from '../fn/student-exam/api-student-exam-get-student-exam-for-admin-get';
import { apiStudentExamGetStudentExamForAdminGet$Plain } from '../fn/student-exam/api-student-exam-get-student-exam-for-admin-get-plain';
import { ApiStudentExamGetStudentExamForAdminGet$Plain$Params } from '../fn/student-exam/api-student-exam-get-student-exam-for-admin-get-plain';
import { apiStudentExamGetStudentExamSolvedForAdminGet } from '../fn/student-exam/api-student-exam-get-student-exam-solved-for-admin-get';
import { ApiStudentExamGetStudentExamSolvedForAdminGet$Params } from '../fn/student-exam/api-student-exam-get-student-exam-solved-for-admin-get';
import { apiStudentExamGetStudentExamSolvedForAdminGet$Plain } from '../fn/student-exam/api-student-exam-get-student-exam-solved-for-admin-get-plain';
import { ApiStudentExamGetStudentExamSolvedForAdminGet$Plain$Params } from '../fn/student-exam/api-student-exam-get-student-exam-solved-for-admin-get-plain';
import { apiStudentExamRemoveStudentExamForAdminDelete } from '../fn/student-exam/api-student-exam-remove-student-exam-for-admin-delete';
import { ApiStudentExamRemoveStudentExamForAdminDelete$Params } from '../fn/student-exam/api-student-exam-remove-student-exam-for-admin-delete';
import { apiStudentExamRemoveStudentExamForAdminDelete$Plain } from '../fn/student-exam/api-student-exam-remove-student-exam-for-admin-delete-plain';
import { ApiStudentExamRemoveStudentExamForAdminDelete$Plain$Params } from '../fn/student-exam/api-student-exam-remove-student-exam-for-admin-delete-plain';
import { apiStudentExamStartExamPost } from '../fn/student-exam/api-student-exam-start-exam-post';
import { ApiStudentExamStartExamPost$Params } from '../fn/student-exam/api-student-exam-start-exam-post';
import { apiStudentExamStartExamPost$Plain } from '../fn/student-exam/api-student-exam-start-exam-post-plain';
import { ApiStudentExamStartExamPost$Plain$Params } from '../fn/student-exam/api-student-exam-start-exam-post-plain';
import { ObjectPagination } from '../models/object-pagination';
import { StudentAnswerDto } from '../models/student-answer-dto';
import { StudentAnswerDtoListPagination } from '../models/student-answer-dto-list-pagination';
import { StudentExamSolvedDto } from '../models/student-exam-solved-dto';
import { StudentExamSolvedDtoPagination } from '../models/student-exam-solved-dto-pagination';
import { ViewExamDto } from '../models/view-exam-dto';
import { ViewExamDtoPagination } from '../models/view-exam-dto-pagination';
import { ViewStudentExamAdminDtoListPagination } from '../models/view-student-exam-admin-dto-list-pagination';
import { ViewStudentExamAdminDtoPagination } from '../models/view-student-exam-admin-dto-pagination';
import { ViewStudentExamDto } from '../models/view-student-exam-dto';

@Injectable({ providedIn: 'root' })
export class StudentExamService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiStudentExamStartExamPost()` */
  static readonly ApiStudentExamStartExamPostPath = '/api/StudentExam/StartExam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamStartExamPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamStartExamPost$Plain$Response(params?: ApiStudentExamStartExamPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewExamDtoPagination>> {
    return apiStudentExamStartExamPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamStartExamPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamStartExamPost$Plain(params?: ApiStudentExamStartExamPost$Plain$Params, context?: HttpContext): Observable<ViewExamDtoPagination> {
    return this.apiStudentExamStartExamPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewExamDtoPagination>): ViewExamDtoPagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamStartExamPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamStartExamPost$Response(params?: ApiStudentExamStartExamPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewExamDtoPagination>> {
    return apiStudentExamStartExamPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamStartExamPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamStartExamPost(params?: ApiStudentExamStartExamPost$Params, context?: HttpContext): Observable<ViewExamDtoPagination> {
    return this.apiStudentExamStartExamPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewExamDtoPagination>): ViewExamDtoPagination => r.body)
    );
  }

  /** Path part for operation `apiStudentExamGetSolvedStudentExamsForStudentGet()` */
  static readonly ApiStudentExamGetSolvedStudentExamsForStudentGetPath = '/api/StudentExam/GetSolvedStudentExamsForStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamGetSolvedStudentExamsForStudentGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetSolvedStudentExamsForStudentGet$Plain$Response(params?: ApiStudentExamGetSolvedStudentExamsForStudentGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewStudentExamDto>>> {
    return apiStudentExamGetSolvedStudentExamsForStudentGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamGetSolvedStudentExamsForStudentGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetSolvedStudentExamsForStudentGet$Plain(params?: ApiStudentExamGetSolvedStudentExamsForStudentGet$Plain$Params, context?: HttpContext): Observable<Array<ViewStudentExamDto>> {
    return this.apiStudentExamGetSolvedStudentExamsForStudentGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewStudentExamDto>>): Array<ViewStudentExamDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamGetSolvedStudentExamsForStudentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetSolvedStudentExamsForStudentGet$Response(params?: ApiStudentExamGetSolvedStudentExamsForStudentGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewStudentExamDto>>> {
    return apiStudentExamGetSolvedStudentExamsForStudentGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamGetSolvedStudentExamsForStudentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetSolvedStudentExamsForStudentGet(params?: ApiStudentExamGetSolvedStudentExamsForStudentGet$Params, context?: HttpContext): Observable<Array<ViewStudentExamDto>> {
    return this.apiStudentExamGetSolvedStudentExamsForStudentGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewStudentExamDto>>): Array<ViewStudentExamDto> => r.body)
    );
  }

  /** Path part for operation `apiStudentExamFinishStudentExamGet()` */
  static readonly ApiStudentExamFinishStudentExamGetPath = '/api/StudentExam/FinishStudentExam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamFinishStudentExamGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamFinishStudentExamGet$Plain$Response(params?: ApiStudentExamFinishStudentExamGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentExamAdminDtoPagination>> {
    return apiStudentExamFinishStudentExamGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamFinishStudentExamGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamFinishStudentExamGet$Plain(params?: ApiStudentExamFinishStudentExamGet$Plain$Params, context?: HttpContext): Observable<ViewStudentExamAdminDtoPagination> {
    return this.apiStudentExamFinishStudentExamGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentExamAdminDtoPagination>): ViewStudentExamAdminDtoPagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamFinishStudentExamGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamFinishStudentExamGet$Response(params?: ApiStudentExamFinishStudentExamGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentExamAdminDtoPagination>> {
    return apiStudentExamFinishStudentExamGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamFinishStudentExamGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamFinishStudentExamGet(params?: ApiStudentExamFinishStudentExamGet$Params, context?: HttpContext): Observable<ViewStudentExamAdminDtoPagination> {
    return this.apiStudentExamFinishStudentExamGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentExamAdminDtoPagination>): ViewStudentExamAdminDtoPagination => r.body)
    );
  }

  /** Path part for operation `apiStudentExamGetNewStudentExamsGet()` */
  static readonly ApiStudentExamGetNewStudentExamsGetPath = '/api/StudentExam/GetNewStudentExams';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamGetNewStudentExamsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetNewStudentExamsGet$Plain$Response(params?: ApiStudentExamGetNewStudentExamsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewExamDto>>> {
    return apiStudentExamGetNewStudentExamsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamGetNewStudentExamsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetNewStudentExamsGet$Plain(params?: ApiStudentExamGetNewStudentExamsGet$Plain$Params, context?: HttpContext): Observable<Array<ViewExamDto>> {
    return this.apiStudentExamGetNewStudentExamsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewExamDto>>): Array<ViewExamDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamGetNewStudentExamsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetNewStudentExamsGet$Response(params?: ApiStudentExamGetNewStudentExamsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewExamDto>>> {
    return apiStudentExamGetNewStudentExamsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamGetNewStudentExamsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetNewStudentExamsGet(params?: ApiStudentExamGetNewStudentExamsGet$Params, context?: HttpContext): Observable<Array<ViewExamDto>> {
    return this.apiStudentExamGetNewStudentExamsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewExamDto>>): Array<ViewExamDto> => r.body)
    );
  }

  /** Path part for operation `apiStudentExamAddStudentExamAnswerPost()` */
  static readonly ApiStudentExamAddStudentExamAnswerPostPath = '/api/StudentExam/AddStudentExamAnswer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamAddStudentExamAnswerPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentExamAddStudentExamAnswerPost$Plain$Response(params?: ApiStudentExamAddStudentExamAnswerPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StudentAnswerDtoListPagination>> {
    return apiStudentExamAddStudentExamAnswerPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamAddStudentExamAnswerPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentExamAddStudentExamAnswerPost$Plain(params?: ApiStudentExamAddStudentExamAnswerPost$Plain$Params, context?: HttpContext): Observable<StudentAnswerDtoListPagination> {
    return this.apiStudentExamAddStudentExamAnswerPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StudentAnswerDtoListPagination>): StudentAnswerDtoListPagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamAddStudentExamAnswerPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentExamAddStudentExamAnswerPost$Response(params?: ApiStudentExamAddStudentExamAnswerPost$Params, context?: HttpContext): Observable<StrictHttpResponse<StudentAnswerDtoListPagination>> {
    return apiStudentExamAddStudentExamAnswerPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamAddStudentExamAnswerPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentExamAddStudentExamAnswerPost(params?: ApiStudentExamAddStudentExamAnswerPost$Params, context?: HttpContext): Observable<StudentAnswerDtoListPagination> {
    return this.apiStudentExamAddStudentExamAnswerPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<StudentAnswerDtoListPagination>): StudentAnswerDtoListPagination => r.body)
    );
  }

  /** Path part for operation `apiStudentExamGetStudentAnswerForExamGet()` */
  static readonly ApiStudentExamGetStudentAnswerForExamGetPath = '/api/StudentExam/GetStudentAnswerForExam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamGetStudentAnswerForExamGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetStudentAnswerForExamGet$Plain$Response(params?: ApiStudentExamGetStudentAnswerForExamGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<StudentAnswerDto>>> {
    return apiStudentExamGetStudentAnswerForExamGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamGetStudentAnswerForExamGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetStudentAnswerForExamGet$Plain(params?: ApiStudentExamGetStudentAnswerForExamGet$Plain$Params, context?: HttpContext): Observable<Array<StudentAnswerDto>> {
    return this.apiStudentExamGetStudentAnswerForExamGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StudentAnswerDto>>): Array<StudentAnswerDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamGetStudentAnswerForExamGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetStudentAnswerForExamGet$Response(params?: ApiStudentExamGetStudentAnswerForExamGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<StudentAnswerDto>>> {
    return apiStudentExamGetStudentAnswerForExamGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamGetStudentAnswerForExamGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetStudentAnswerForExamGet(params?: ApiStudentExamGetStudentAnswerForExamGet$Params, context?: HttpContext): Observable<Array<StudentAnswerDto>> {
    return this.apiStudentExamGetStudentAnswerForExamGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StudentAnswerDto>>): Array<StudentAnswerDto> => r.body)
    );
  }

  /** Path part for operation `apiStudentExamGetStudentAnswerModelForExamGet()` */
  static readonly ApiStudentExamGetStudentAnswerModelForExamGetPath = '/api/StudentExam/GetStudentAnswerModelForExam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamGetStudentAnswerModelForExamGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetStudentAnswerModelForExamGet$Plain$Response(params?: ApiStudentExamGetStudentAnswerModelForExamGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<StudentAnswerDto>>> {
    return apiStudentExamGetStudentAnswerModelForExamGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamGetStudentAnswerModelForExamGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetStudentAnswerModelForExamGet$Plain(params?: ApiStudentExamGetStudentAnswerModelForExamGet$Plain$Params, context?: HttpContext): Observable<Array<StudentAnswerDto>> {
    return this.apiStudentExamGetStudentAnswerModelForExamGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StudentAnswerDto>>): Array<StudentAnswerDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamGetStudentAnswerModelForExamGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetStudentAnswerModelForExamGet$Response(params?: ApiStudentExamGetStudentAnswerModelForExamGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<StudentAnswerDto>>> {
    return apiStudentExamGetStudentAnswerModelForExamGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamGetStudentAnswerModelForExamGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetStudentAnswerModelForExamGet(params?: ApiStudentExamGetStudentAnswerModelForExamGet$Params, context?: HttpContext): Observable<Array<StudentAnswerDto>> {
    return this.apiStudentExamGetStudentAnswerModelForExamGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StudentAnswerDto>>): Array<StudentAnswerDto> => r.body)
    );
  }

  /** Path part for operation `apiStudentExamGetStudentExamForAdminGet()` */
  static readonly ApiStudentExamGetStudentExamForAdminGetPath = '/api/StudentExam/GetStudentExamForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamGetStudentExamForAdminGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetStudentExamForAdminGet$Plain$Response(params?: ApiStudentExamGetStudentExamForAdminGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentExamAdminDtoListPagination>> {
    return apiStudentExamGetStudentExamForAdminGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamGetStudentExamForAdminGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetStudentExamForAdminGet$Plain(params?: ApiStudentExamGetStudentExamForAdminGet$Plain$Params, context?: HttpContext): Observable<ViewStudentExamAdminDtoListPagination> {
    return this.apiStudentExamGetStudentExamForAdminGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentExamAdminDtoListPagination>): ViewStudentExamAdminDtoListPagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamGetStudentExamForAdminGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetStudentExamForAdminGet$Response(params?: ApiStudentExamGetStudentExamForAdminGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentExamAdminDtoListPagination>> {
    return apiStudentExamGetStudentExamForAdminGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamGetStudentExamForAdminGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetStudentExamForAdminGet(params?: ApiStudentExamGetStudentExamForAdminGet$Params, context?: HttpContext): Observable<ViewStudentExamAdminDtoListPagination> {
    return this.apiStudentExamGetStudentExamForAdminGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentExamAdminDtoListPagination>): ViewStudentExamAdminDtoListPagination => r.body)
    );
  }

  /** Path part for operation `apiStudentExamRemoveStudentExamForAdminDelete()` */
  static readonly ApiStudentExamRemoveStudentExamForAdminDeletePath = '/api/StudentExam/RemoveStudentExamForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamRemoveStudentExamForAdminDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamRemoveStudentExamForAdminDelete$Plain$Response(params?: ApiStudentExamRemoveStudentExamForAdminDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ObjectPagination>> {
    return apiStudentExamRemoveStudentExamForAdminDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamRemoveStudentExamForAdminDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamRemoveStudentExamForAdminDelete$Plain(params?: ApiStudentExamRemoveStudentExamForAdminDelete$Plain$Params, context?: HttpContext): Observable<ObjectPagination> {
    return this.apiStudentExamRemoveStudentExamForAdminDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ObjectPagination>): ObjectPagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamRemoveStudentExamForAdminDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamRemoveStudentExamForAdminDelete$Response(params?: ApiStudentExamRemoveStudentExamForAdminDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<ObjectPagination>> {
    return apiStudentExamRemoveStudentExamForAdminDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamRemoveStudentExamForAdminDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamRemoveStudentExamForAdminDelete(params?: ApiStudentExamRemoveStudentExamForAdminDelete$Params, context?: HttpContext): Observable<ObjectPagination> {
    return this.apiStudentExamRemoveStudentExamForAdminDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<ObjectPagination>): ObjectPagination => r.body)
    );
  }

  /** Path part for operation `apiStudentExamGetStudentExamSolvedForAdminGet()` */
  static readonly ApiStudentExamGetStudentExamSolvedForAdminGetPath = '/api/StudentExam/GetStudentExamSolvedForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamGetStudentExamSolvedForAdminGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetStudentExamSolvedForAdminGet$Plain$Response(params?: ApiStudentExamGetStudentExamSolvedForAdminGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StudentExamSolvedDto>> {
    return apiStudentExamGetStudentExamSolvedForAdminGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamGetStudentExamSolvedForAdminGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetStudentExamSolvedForAdminGet$Plain(params?: ApiStudentExamGetStudentExamSolvedForAdminGet$Plain$Params, context?: HttpContext): Observable<StudentExamSolvedDto> {
    return this.apiStudentExamGetStudentExamSolvedForAdminGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StudentExamSolvedDto>): StudentExamSolvedDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamGetStudentExamSolvedForAdminGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetStudentExamSolvedForAdminGet$Response(params?: ApiStudentExamGetStudentExamSolvedForAdminGet$Params, context?: HttpContext): Observable<StrictHttpResponse<StudentExamSolvedDto>> {
    return apiStudentExamGetStudentExamSolvedForAdminGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamGetStudentExamSolvedForAdminGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentExamGetStudentExamSolvedForAdminGet(params?: ApiStudentExamGetStudentExamSolvedForAdminGet$Params, context?: HttpContext): Observable<StudentExamSolvedDto> {
    return this.apiStudentExamGetStudentExamSolvedForAdminGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<StudentExamSolvedDto>): StudentExamSolvedDto => r.body)
    );
  }

  /** Path part for operation `apiStudentExamCorrectArticleAnswerPost()` */
  static readonly ApiStudentExamCorrectArticleAnswerPostPath = '/api/StudentExam/CorrectArticleAnswer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamCorrectArticleAnswerPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentExamCorrectArticleAnswerPost$Plain$Response(params?: ApiStudentExamCorrectArticleAnswerPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StudentExamSolvedDtoPagination>> {
    return apiStudentExamCorrectArticleAnswerPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamCorrectArticleAnswerPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentExamCorrectArticleAnswerPost$Plain(params?: ApiStudentExamCorrectArticleAnswerPost$Plain$Params, context?: HttpContext): Observable<StudentExamSolvedDtoPagination> {
    return this.apiStudentExamCorrectArticleAnswerPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StudentExamSolvedDtoPagination>): StudentExamSolvedDtoPagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentExamCorrectArticleAnswerPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentExamCorrectArticleAnswerPost$Response(params?: ApiStudentExamCorrectArticleAnswerPost$Params, context?: HttpContext): Observable<StrictHttpResponse<StudentExamSolvedDtoPagination>> {
    return apiStudentExamCorrectArticleAnswerPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentExamCorrectArticleAnswerPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentExamCorrectArticleAnswerPost(params?: ApiStudentExamCorrectArticleAnswerPost$Params, context?: HttpContext): Observable<StudentExamSolvedDtoPagination> {
    return this.apiStudentExamCorrectArticleAnswerPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<StudentExamSolvedDtoPagination>): StudentExamSolvedDtoPagination => r.body)
    );
  }

}
