/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiSubjectChangeStatusPut } from '../fn/subject/api-subject-change-status-put';
import { ApiSubjectChangeStatusPut$Params } from '../fn/subject/api-subject-change-status-put';
import { apiSubjectGetAllSubjectsGet } from '../fn/subject/api-subject-get-all-subjects-get';
import { ApiSubjectGetAllSubjectsGet$Params } from '../fn/subject/api-subject-get-all-subjects-get';
import { apiSubjectGetAllSubjectsGet$Plain } from '../fn/subject/api-subject-get-all-subjects-get-plain';
import { ApiSubjectGetAllSubjectsGet$Plain$Params } from '../fn/subject/api-subject-get-all-subjects-get-plain';
import { apiSubjectIdDelete } from '../fn/subject/api-subject-id-delete';
import { ApiSubjectIdDelete$Params } from '../fn/subject/api-subject-id-delete';
import { apiSubjectIdGet } from '../fn/subject/api-subject-id-get';
import { ApiSubjectIdGet$Params } from '../fn/subject/api-subject-id-get';
import { apiSubjectIdGet$Plain } from '../fn/subject/api-subject-id-get-plain';
import { ApiSubjectIdGet$Plain$Params } from '../fn/subject/api-subject-id-get-plain';
import { apiSubjectIdPut } from '../fn/subject/api-subject-id-put';
import { ApiSubjectIdPut$Params } from '../fn/subject/api-subject-id-put';
import { apiSubjectPost } from '../fn/subject/api-subject-post';
import { ApiSubjectPost$Params } from '../fn/subject/api-subject-post';
import { ViewSubjectDto } from '../models/view-subject-dto';
import { ViewSubjectDtoIEnumerablePagination } from '../models/view-subject-dto-i-enumerable-pagination';

@Injectable({ providedIn: 'root' })
export class SubjectService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiSubjectGetAllSubjectsGet()` */
  static readonly ApiSubjectGetAllSubjectsGetPath = '/api/Subject/getAllSubjects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubjectGetAllSubjectsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubjectGetAllSubjectsGet$Plain$Response(params?: ApiSubjectGetAllSubjectsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewSubjectDtoIEnumerablePagination>> {
    return apiSubjectGetAllSubjectsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSubjectGetAllSubjectsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubjectGetAllSubjectsGet$Plain(params?: ApiSubjectGetAllSubjectsGet$Plain$Params, context?: HttpContext): Observable<ViewSubjectDtoIEnumerablePagination> {
    return this.apiSubjectGetAllSubjectsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewSubjectDtoIEnumerablePagination>): ViewSubjectDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubjectGetAllSubjectsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubjectGetAllSubjectsGet$Response(params?: ApiSubjectGetAllSubjectsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewSubjectDtoIEnumerablePagination>> {
    return apiSubjectGetAllSubjectsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSubjectGetAllSubjectsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubjectGetAllSubjectsGet(params?: ApiSubjectGetAllSubjectsGet$Params, context?: HttpContext): Observable<ViewSubjectDtoIEnumerablePagination> {
    return this.apiSubjectGetAllSubjectsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewSubjectDtoIEnumerablePagination>): ViewSubjectDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiSubjectIdGet()` */
  static readonly ApiSubjectIdGetPath = '/api/Subject/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubjectIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubjectIdGet$Plain$Response(params: ApiSubjectIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewSubjectDto>> {
    return apiSubjectIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSubjectIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubjectIdGet$Plain(params: ApiSubjectIdGet$Plain$Params, context?: HttpContext): Observable<ViewSubjectDto> {
    return this.apiSubjectIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewSubjectDto>): ViewSubjectDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubjectIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubjectIdGet$Response(params: ApiSubjectIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewSubjectDto>> {
    return apiSubjectIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSubjectIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubjectIdGet(params: ApiSubjectIdGet$Params, context?: HttpContext): Observable<ViewSubjectDto> {
    return this.apiSubjectIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewSubjectDto>): ViewSubjectDto => r.body)
    );
  }

  /** Path part for operation `apiSubjectIdPut()` */
  static readonly ApiSubjectIdPutPath = '/api/Subject/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubjectIdPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiSubjectIdPut$Response(params: ApiSubjectIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiSubjectIdPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSubjectIdPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiSubjectIdPut(params: ApiSubjectIdPut$Params, context?: HttpContext): Observable<void> {
    return this.apiSubjectIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSubjectIdDelete()` */
  static readonly ApiSubjectIdDeletePath = '/api/Subject/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubjectIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubjectIdDelete$Response(params: ApiSubjectIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiSubjectIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSubjectIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubjectIdDelete(params: ApiSubjectIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiSubjectIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSubjectChangeStatusPut()` */
  static readonly ApiSubjectChangeStatusPutPath = '/api/Subject/ChangeStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubjectChangeStatusPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubjectChangeStatusPut$Response(params?: ApiSubjectChangeStatusPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiSubjectChangeStatusPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSubjectChangeStatusPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubjectChangeStatusPut(params?: ApiSubjectChangeStatusPut$Params, context?: HttpContext): Observable<void> {
    return this.apiSubjectChangeStatusPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSubjectPost()` */
  static readonly ApiSubjectPostPath = '/api/Subject';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubjectPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiSubjectPost$Response(params?: ApiSubjectPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiSubjectPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSubjectPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiSubjectPost(params?: ApiSubjectPost$Params, context?: HttpContext): Observable<void> {
    return this.apiSubjectPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
