/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ViewStudentExamAdminDtoListPagination } from '../../models/view-student-exam-admin-dto-list-pagination';

export interface ApiStudentExamGetStudentExamForAdminGet$Params {
  ExamId?: number;
  pageNumber?: number;
  pageSize?: number;
  searchTerm?: string;
}

export function apiStudentExamGetStudentExamForAdminGet(http: HttpClient, rootUrl: string, params?: ApiStudentExamGetStudentExamForAdminGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentExamAdminDtoListPagination>> {
  const rb = new RequestBuilder(rootUrl, apiStudentExamGetStudentExamForAdminGet.PATH, 'get');
  if (params) {
    rb.query('ExamId', params.ExamId, {});
    rb.query('pageNumber', params.pageNumber, {});
    rb.query('pageSize', params.pageSize, {});
    rb.query('searchTerm', params.searchTerm, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ViewStudentExamAdminDtoListPagination>;
    })
  );
}

apiStudentExamGetStudentExamForAdminGet.PATH = '/api/StudentExam/GetStudentExamForAdmin';
