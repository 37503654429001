/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiQuestionAddEditQuestionDtoPut } from '../fn/question/api-question-add-edit-question-dto-put';
import { ApiQuestionAddEditQuestionDtoPut$Params } from '../fn/question/api-question-add-edit-question-dto-put';
import { apiQuestionGet } from '../fn/question/api-question-get';
import { ApiQuestionGet$Params } from '../fn/question/api-question-get';
import { apiQuestionGet$Plain } from '../fn/question/api-question-get-plain';
import { ApiQuestionGet$Plain$Params } from '../fn/question/api-question-get-plain';
import { apiQuestionIdDelete } from '../fn/question/api-question-id-delete';
import { ApiQuestionIdDelete$Params } from '../fn/question/api-question-id-delete';
import { apiQuestionIdGet } from '../fn/question/api-question-id-get';
import { ApiQuestionIdGet$Params } from '../fn/question/api-question-id-get';
import { apiQuestionIdGet$Plain } from '../fn/question/api-question-id-get-plain';
import { ApiQuestionIdGet$Plain$Params } from '../fn/question/api-question-id-get-plain';
import { apiQuestionPost } from '../fn/question/api-question-post';
import { ApiQuestionPost$Params } from '../fn/question/api-question-post';
import { ViewQuestionDto } from '../models/view-question-dto';
import { ViewQuestionDtoIEnumerablePagination } from '../models/view-question-dto-i-enumerable-pagination';

@Injectable({ providedIn: 'root' })
export class QuestionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiQuestionGet()` */
  static readonly ApiQuestionGetPath = '/api/Question';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionGet$Plain$Response(params?: ApiQuestionGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewQuestionDtoIEnumerablePagination>> {
    return apiQuestionGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionGet$Plain(params?: ApiQuestionGet$Plain$Params, context?: HttpContext): Observable<ViewQuestionDtoIEnumerablePagination> {
    return this.apiQuestionGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewQuestionDtoIEnumerablePagination>): ViewQuestionDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionGet$Response(params?: ApiQuestionGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewQuestionDtoIEnumerablePagination>> {
    return apiQuestionGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionGet(params?: ApiQuestionGet$Params, context?: HttpContext): Observable<ViewQuestionDtoIEnumerablePagination> {
    return this.apiQuestionGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewQuestionDtoIEnumerablePagination>): ViewQuestionDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiQuestionPost()` */
  static readonly ApiQuestionPostPath = '/api/Question';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQuestionPost$Response(params?: ApiQuestionPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiQuestionPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQuestionPost(params?: ApiQuestionPost$Params, context?: HttpContext): Observable<void> {
    return this.apiQuestionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiQuestionIdGet()` */
  static readonly ApiQuestionIdGetPath = '/api/Question/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionIdGet$Plain$Response(params: ApiQuestionIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewQuestionDto>> {
    return apiQuestionIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionIdGet$Plain(params: ApiQuestionIdGet$Plain$Params, context?: HttpContext): Observable<ViewQuestionDto> {
    return this.apiQuestionIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewQuestionDto>): ViewQuestionDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionIdGet$Response(params: ApiQuestionIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewQuestionDto>> {
    return apiQuestionIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionIdGet(params: ApiQuestionIdGet$Params, context?: HttpContext): Observable<ViewQuestionDto> {
    return this.apiQuestionIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewQuestionDto>): ViewQuestionDto => r.body)
    );
  }

  /** Path part for operation `apiQuestionIdDelete()` */
  static readonly ApiQuestionIdDeletePath = '/api/Question/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionIdDelete$Response(params: ApiQuestionIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiQuestionIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionIdDelete(params: ApiQuestionIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiQuestionIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiQuestionAddEditQuestionDtoPut()` */
  static readonly ApiQuestionAddEditQuestionDtoPutPath = '/api/Question/AddEditQuestionDto';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionAddEditQuestionDtoPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQuestionAddEditQuestionDtoPut$Response(params?: ApiQuestionAddEditQuestionDtoPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiQuestionAddEditQuestionDtoPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionAddEditQuestionDtoPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQuestionAddEditQuestionDtoPut(params?: ApiQuestionAddEditQuestionDtoPut$Params, context?: HttpContext): Observable<void> {
    return this.apiQuestionAddEditQuestionDtoPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
