/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ViewStudentCourseDtoIEnumerablePagination } from '../../models/view-student-course-dto-i-enumerable-pagination';

export interface ApiStudentGetCoursesGet$Plain$Params {
  SubId?: number;
  pageSize?: number;
  pageNumbe?: number;
}

export function apiStudentGetCoursesGet$Plain(http: HttpClient, rootUrl: string, params?: ApiStudentGetCoursesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentCourseDtoIEnumerablePagination>> {
  const rb = new RequestBuilder(rootUrl, apiStudentGetCoursesGet$Plain.PATH, 'get');
  if (params) {
    rb.query('SubId', params.SubId, {});
    rb.query('pageSize', params.pageSize, {});
    rb.query('pageNumbe', params.pageNumbe, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ViewStudentCourseDtoIEnumerablePagination>;
    })
  );
}

apiStudentGetCoursesGet$Plain.PATH = '/api/Student/GetCourses';
