/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiRateCourseGet } from '../fn/rate-course/api-rate-course-get';
import { ApiRateCourseGet$Params } from '../fn/rate-course/api-rate-course-get';
import { apiRateCourseGet$Plain } from '../fn/rate-course/api-rate-course-get-plain';
import { ApiRateCourseGet$Plain$Params } from '../fn/rate-course/api-rate-course-get-plain';
import { apiRateCourseIdDelete } from '../fn/rate-course/api-rate-course-id-delete';
import { ApiRateCourseIdDelete$Params } from '../fn/rate-course/api-rate-course-id-delete';
import { apiRateCourseIdGet } from '../fn/rate-course/api-rate-course-id-get';
import { ApiRateCourseIdGet$Params } from '../fn/rate-course/api-rate-course-id-get';
import { apiRateCourseIdGet$Plain } from '../fn/rate-course/api-rate-course-id-get-plain';
import { ApiRateCourseIdGet$Plain$Params } from '../fn/rate-course/api-rate-course-id-get-plain';
import { apiRateCoursePost } from '../fn/rate-course/api-rate-course-post';
import { ApiRateCoursePost$Params } from '../fn/rate-course/api-rate-course-post';
import { ViewRateCourseDto } from '../models/view-rate-course-dto';
import { ViewRateCourseDtoIEnumerablePagination } from '../models/view-rate-course-dto-i-enumerable-pagination';

@Injectable({ providedIn: 'root' })
export class RateCourseService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiRateCourseGet()` */
  static readonly ApiRateCourseGetPath = '/api/RateCourse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRateCourseGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateCourseGet$Plain$Response(params?: ApiRateCourseGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewRateCourseDtoIEnumerablePagination>> {
    return apiRateCourseGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRateCourseGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateCourseGet$Plain(params?: ApiRateCourseGet$Plain$Params, context?: HttpContext): Observable<ViewRateCourseDtoIEnumerablePagination> {
    return this.apiRateCourseGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewRateCourseDtoIEnumerablePagination>): ViewRateCourseDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRateCourseGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateCourseGet$Response(params?: ApiRateCourseGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewRateCourseDtoIEnumerablePagination>> {
    return apiRateCourseGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRateCourseGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateCourseGet(params?: ApiRateCourseGet$Params, context?: HttpContext): Observable<ViewRateCourseDtoIEnumerablePagination> {
    return this.apiRateCourseGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewRateCourseDtoIEnumerablePagination>): ViewRateCourseDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiRateCoursePost()` */
  static readonly ApiRateCoursePostPath = '/api/RateCourse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRateCoursePost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiRateCoursePost$Response(params?: ApiRateCoursePost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiRateCoursePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRateCoursePost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiRateCoursePost(params?: ApiRateCoursePost$Params, context?: HttpContext): Observable<void> {
    return this.apiRateCoursePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiRateCourseIdGet()` */
  static readonly ApiRateCourseIdGetPath = '/api/RateCourse/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRateCourseIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateCourseIdGet$Plain$Response(params: ApiRateCourseIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewRateCourseDto>> {
    return apiRateCourseIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRateCourseIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateCourseIdGet$Plain(params: ApiRateCourseIdGet$Plain$Params, context?: HttpContext): Observable<ViewRateCourseDto> {
    return this.apiRateCourseIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewRateCourseDto>): ViewRateCourseDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRateCourseIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateCourseIdGet$Response(params: ApiRateCourseIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewRateCourseDto>> {
    return apiRateCourseIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRateCourseIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateCourseIdGet(params: ApiRateCourseIdGet$Params, context?: HttpContext): Observable<ViewRateCourseDto> {
    return this.apiRateCourseIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewRateCourseDto>): ViewRateCourseDto => r.body)
    );
  }

  /** Path part for operation `apiRateCourseIdDelete()` */
  static readonly ApiRateCourseIdDeletePath = '/api/RateCourse/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRateCourseIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateCourseIdDelete$Response(params: ApiRateCourseIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiRateCourseIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRateCourseIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateCourseIdDelete(params: ApiRateCourseIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiRateCourseIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
