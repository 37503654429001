/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccountService } from './services/account.service';
import { ActivityService } from './services/activity.service';
import { BannerService } from './services/banner.service';
import { ChapterService } from './services/chapter.service';
import { CommentService } from './services/comment.service';
import { CourseService } from './services/course.service';
import { ExamService } from './services/exam.service';
import { LevelService } from './services/level.service';
import { LevelYearsService } from './services/level-years.service';
import { NotificationService } from './services/notification.service';
import { ParentService } from './services/parent.service';
import { QuestionService } from './services/question.service';
import { RateCourseService } from './services/rate-course.service';
import { RateTeacherService } from './services/rate-teacher.service';
import { RateVideoService } from './services/rate-video.service';
import { StatsService } from './services/stats.service';
import { StudentService } from './services/student.service';
import { StudentExamService } from './services/student-exam.service';
import { SubjectService } from './services/subject.service';
import { TeacherService } from './services/teacher.service';
import { ToDoListService } from './services/to-do-list.service';
import { VideoCourseService } from './services/video-course.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountService,
    ActivityService,
    BannerService,
    ChapterService,
    CommentService,
    CourseService,
    ExamService,
    LevelService,
    LevelYearsService,
    NotificationService,
    ParentService,
    QuestionService,
    RateCourseService,
    RateTeacherService,
    RateVideoService,
    StatsService,
    StudentService,
    StudentExamService,
    SubjectService,
    TeacherService,
    ToDoListService,
    VideoCourseService,
    ApiConfiguration
  ],
})
export class BEApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<BEApiModule> {
    return {
      ngModule: BEApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: BEApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('BEApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
