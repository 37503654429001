/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ViewVideoCourseDtoIEnumerablePagination } from '../../models/view-video-course-dto-i-enumerable-pagination';

export interface ApiVideoCourseGet$Plain$Params {
  pageSize?: number;
  pageNumbe?: number;
  chapterId?: number;
  SearchValue?: string;
}

export function apiVideoCourseGet$Plain(http: HttpClient, rootUrl: string, params?: ApiVideoCourseGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewVideoCourseDtoIEnumerablePagination>> {
  const rb = new RequestBuilder(rootUrl, apiVideoCourseGet$Plain.PATH, 'get');
  if (params) {
    rb.query('pageSize', params.pageSize, {});
    rb.query('pageNumbe', params.pageNumbe, {});
    rb.query('chapterId', params.chapterId, {});
    rb.query('SearchValue', params.SearchValue, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ViewVideoCourseDtoIEnumerablePagination>;
    })
  );
}

apiVideoCourseGet$Plain.PATH = '/api/VideoCourse';
