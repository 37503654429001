/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiActivityGet } from '../fn/activity/api-activity-get';
import { ApiActivityGet$Params } from '../fn/activity/api-activity-get';
import { apiActivityGet$Plain } from '../fn/activity/api-activity-get-plain';
import { ApiActivityGet$Plain$Params } from '../fn/activity/api-activity-get-plain';
import { apiActivityIdDelete } from '../fn/activity/api-activity-id-delete';
import { ApiActivityIdDelete$Params } from '../fn/activity/api-activity-id-delete';
import { apiActivityIdGet } from '../fn/activity/api-activity-id-get';
import { ApiActivityIdGet$Params } from '../fn/activity/api-activity-id-get';
import { apiActivityIdGet$Plain } from '../fn/activity/api-activity-id-get-plain';
import { ApiActivityIdGet$Plain$Params } from '../fn/activity/api-activity-id-get-plain';
import { apiActivityIdPut } from '../fn/activity/api-activity-id-put';
import { ApiActivityIdPut$Params } from '../fn/activity/api-activity-id-put';
import { apiActivityPost } from '../fn/activity/api-activity-post';
import { ApiActivityPost$Params } from '../fn/activity/api-activity-post';
import { ViewActivityDto } from '../models/view-activity-dto';
import { ViewActivityDtoIEnumerablePagination } from '../models/view-activity-dto-i-enumerable-pagination';

@Injectable({ providedIn: 'root' })
export class ActivityService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiActivityGet()` */
  static readonly ApiActivityGetPath = '/api/Activity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiActivityGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiActivityGet$Plain$Response(params?: ApiActivityGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewActivityDtoIEnumerablePagination>> {
    return apiActivityGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiActivityGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiActivityGet$Plain(params?: ApiActivityGet$Plain$Params, context?: HttpContext): Observable<ViewActivityDtoIEnumerablePagination> {
    return this.apiActivityGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewActivityDtoIEnumerablePagination>): ViewActivityDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiActivityGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiActivityGet$Response(params?: ApiActivityGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewActivityDtoIEnumerablePagination>> {
    return apiActivityGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiActivityGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiActivityGet(params?: ApiActivityGet$Params, context?: HttpContext): Observable<ViewActivityDtoIEnumerablePagination> {
    return this.apiActivityGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewActivityDtoIEnumerablePagination>): ViewActivityDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiActivityPost()` */
  static readonly ApiActivityPostPath = '/api/Activity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiActivityPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiActivityPost$Response(params?: ApiActivityPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiActivityPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiActivityPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiActivityPost(params?: ApiActivityPost$Params, context?: HttpContext): Observable<void> {
    return this.apiActivityPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiActivityIdGet()` */
  static readonly ApiActivityIdGetPath = '/api/Activity/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiActivityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiActivityIdGet$Plain$Response(params: ApiActivityIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewActivityDto>> {
    return apiActivityIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiActivityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiActivityIdGet$Plain(params: ApiActivityIdGet$Plain$Params, context?: HttpContext): Observable<ViewActivityDto> {
    return this.apiActivityIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewActivityDto>): ViewActivityDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiActivityIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiActivityIdGet$Response(params: ApiActivityIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewActivityDto>> {
    return apiActivityIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiActivityIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiActivityIdGet(params: ApiActivityIdGet$Params, context?: HttpContext): Observable<ViewActivityDto> {
    return this.apiActivityIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewActivityDto>): ViewActivityDto => r.body)
    );
  }

  /** Path part for operation `apiActivityIdPut()` */
  static readonly ApiActivityIdPutPath = '/api/Activity/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiActivityIdPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiActivityIdPut$Response(params: ApiActivityIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiActivityIdPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiActivityIdPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiActivityIdPut(params: ApiActivityIdPut$Params, context?: HttpContext): Observable<void> {
    return this.apiActivityIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiActivityIdDelete()` */
  static readonly ApiActivityIdDeletePath = '/api/Activity/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiActivityIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiActivityIdDelete$Response(params: ApiActivityIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiActivityIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiActivityIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiActivityIdDelete(params: ApiActivityIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiActivityIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
