/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiLevelChangeStatusPut } from '../fn/level/api-level-change-status-put';
import { ApiLevelChangeStatusPut$Params } from '../fn/level/api-level-change-status-put';
import { apiLevelGet } from '../fn/level/api-level-get';
import { ApiLevelGet$Params } from '../fn/level/api-level-get';
import { apiLevelGet$Plain } from '../fn/level/api-level-get-plain';
import { ApiLevelGet$Plain$Params } from '../fn/level/api-level-get-plain';
import { apiLevelIdDelete } from '../fn/level/api-level-id-delete';
import { ApiLevelIdDelete$Params } from '../fn/level/api-level-id-delete';
import { apiLevelIdGet } from '../fn/level/api-level-id-get';
import { ApiLevelIdGet$Params } from '../fn/level/api-level-id-get';
import { apiLevelIdGet$Plain } from '../fn/level/api-level-id-get-plain';
import { ApiLevelIdGet$Plain$Params } from '../fn/level/api-level-id-get-plain';
import { apiLevelIdPut } from '../fn/level/api-level-id-put';
import { ApiLevelIdPut$Params } from '../fn/level/api-level-id-put';
import { apiLevelPost } from '../fn/level/api-level-post';
import { ApiLevelPost$Params } from '../fn/level/api-level-post';
import { ViewLevelDto } from '../models/view-level-dto';
import { ViewLevelDtoIEnumerablePagination } from '../models/view-level-dto-i-enumerable-pagination';

@Injectable({ providedIn: 'root' })
export class LevelService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiLevelGet()` */
  static readonly ApiLevelGetPath = '/api/Level';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLevelGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelGet$Plain$Response(params?: ApiLevelGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewLevelDtoIEnumerablePagination>> {
    return apiLevelGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLevelGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelGet$Plain(params?: ApiLevelGet$Plain$Params, context?: HttpContext): Observable<ViewLevelDtoIEnumerablePagination> {
    return this.apiLevelGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewLevelDtoIEnumerablePagination>): ViewLevelDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLevelGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelGet$Response(params?: ApiLevelGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewLevelDtoIEnumerablePagination>> {
    return apiLevelGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLevelGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelGet(params?: ApiLevelGet$Params, context?: HttpContext): Observable<ViewLevelDtoIEnumerablePagination> {
    return this.apiLevelGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewLevelDtoIEnumerablePagination>): ViewLevelDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiLevelPost()` */
  static readonly ApiLevelPostPath = '/api/Level';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLevelPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLevelPost$Response(params?: ApiLevelPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiLevelPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLevelPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLevelPost(params?: ApiLevelPost$Params, context?: HttpContext): Observable<void> {
    return this.apiLevelPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiLevelIdGet()` */
  static readonly ApiLevelIdGetPath = '/api/Level/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLevelIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelIdGet$Plain$Response(params: ApiLevelIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewLevelDto>> {
    return apiLevelIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLevelIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelIdGet$Plain(params: ApiLevelIdGet$Plain$Params, context?: HttpContext): Observable<ViewLevelDto> {
    return this.apiLevelIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewLevelDto>): ViewLevelDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLevelIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelIdGet$Response(params: ApiLevelIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewLevelDto>> {
    return apiLevelIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLevelIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelIdGet(params: ApiLevelIdGet$Params, context?: HttpContext): Observable<ViewLevelDto> {
    return this.apiLevelIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewLevelDto>): ViewLevelDto => r.body)
    );
  }

  /** Path part for operation `apiLevelIdPut()` */
  static readonly ApiLevelIdPutPath = '/api/Level/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLevelIdPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLevelIdPut$Response(params: ApiLevelIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiLevelIdPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLevelIdPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLevelIdPut(params: ApiLevelIdPut$Params, context?: HttpContext): Observable<void> {
    return this.apiLevelIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiLevelIdDelete()` */
  static readonly ApiLevelIdDeletePath = '/api/Level/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLevelIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelIdDelete$Response(params: ApiLevelIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiLevelIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLevelIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelIdDelete(params: ApiLevelIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiLevelIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiLevelChangeStatusPut()` */
  static readonly ApiLevelChangeStatusPutPath = '/api/Level/ChangeStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLevelChangeStatusPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelChangeStatusPut$Response(params?: ApiLevelChangeStatusPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiLevelChangeStatusPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLevelChangeStatusPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelChangeStatusPut(params?: ApiLevelChangeStatusPut$Params, context?: HttpContext): Observable<void> {
    return this.apiLevelChangeStatusPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
