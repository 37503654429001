/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiTeacherAddTeacherRequsetsPost } from '../fn/teacher/api-teacher-add-teacher-requsets-post';
import { ApiTeacherAddTeacherRequsetsPost$Params } from '../fn/teacher/api-teacher-add-teacher-requsets-post';
import { apiTeacherChangeStatusPut } from '../fn/teacher/api-teacher-change-status-put';
import { ApiTeacherChangeStatusPut$Params } from '../fn/teacher/api-teacher-change-status-put';
import { apiTeacherGet } from '../fn/teacher/api-teacher-get';
import { ApiTeacherGet$Params } from '../fn/teacher/api-teacher-get';
import { apiTeacherGet$Plain } from '../fn/teacher/api-teacher-get-plain';
import { ApiTeacherGet$Plain$Params } from '../fn/teacher/api-teacher-get-plain';
import { apiTeacherGetLevelsByTeacherIdGet } from '../fn/teacher/api-teacher-get-levels-by-teacher-id-get';
import { ApiTeacherGetLevelsByTeacherIdGet$Params } from '../fn/teacher/api-teacher-get-levels-by-teacher-id-get';
import { apiTeacherGetLevelsByTeacherIdGet$Plain } from '../fn/teacher/api-teacher-get-levels-by-teacher-id-get-plain';
import { ApiTeacherGetLevelsByTeacherIdGet$Plain$Params } from '../fn/teacher/api-teacher-get-levels-by-teacher-id-get-plain';
import { apiTeacherGetLevelYearsByTeacherIdGet } from '../fn/teacher/api-teacher-get-level-years-by-teacher-id-get';
import { ApiTeacherGetLevelYearsByTeacherIdGet$Params } from '../fn/teacher/api-teacher-get-level-years-by-teacher-id-get';
import { apiTeacherGetLevelYearsByTeacherIdGet$Plain } from '../fn/teacher/api-teacher-get-level-years-by-teacher-id-get-plain';
import { ApiTeacherGetLevelYearsByTeacherIdGet$Plain$Params } from '../fn/teacher/api-teacher-get-level-years-by-teacher-id-get-plain';
import { apiTeacherGetSubjectsByTeacherIdGet } from '../fn/teacher/api-teacher-get-subjects-by-teacher-id-get';
import { ApiTeacherGetSubjectsByTeacherIdGet$Params } from '../fn/teacher/api-teacher-get-subjects-by-teacher-id-get';
import { apiTeacherGetSubjectsByTeacherIdGet$Plain } from '../fn/teacher/api-teacher-get-subjects-by-teacher-id-get-plain';
import { ApiTeacherGetSubjectsByTeacherIdGet$Plain$Params } from '../fn/teacher/api-teacher-get-subjects-by-teacher-id-get-plain';
import { apiTeacherGetTeacherRequsetByIdGet } from '../fn/teacher/api-teacher-get-teacher-requset-by-id-get';
import { ApiTeacherGetTeacherRequsetByIdGet$Params } from '../fn/teacher/api-teacher-get-teacher-requset-by-id-get';
import { apiTeacherGetTeacherRequsetByIdGet$Plain } from '../fn/teacher/api-teacher-get-teacher-requset-by-id-get-plain';
import { ApiTeacherGetTeacherRequsetByIdGet$Plain$Params } from '../fn/teacher/api-teacher-get-teacher-requset-by-id-get-plain';
import { apiTeacherGetTeacherRequsetsGet } from '../fn/teacher/api-teacher-get-teacher-requsets-get';
import { ApiTeacherGetTeacherRequsetsGet$Params } from '../fn/teacher/api-teacher-get-teacher-requsets-get';
import { apiTeacherGetTeacherRequsetsGet$Plain } from '../fn/teacher/api-teacher-get-teacher-requsets-get-plain';
import { ApiTeacherGetTeacherRequsetsGet$Plain$Params } from '../fn/teacher/api-teacher-get-teacher-requsets-get-plain';
import { apiTeacherIdDelete } from '../fn/teacher/api-teacher-id-delete';
import { ApiTeacherIdDelete$Params } from '../fn/teacher/api-teacher-id-delete';
import { apiTeacherIdGet } from '../fn/teacher/api-teacher-id-get';
import { ApiTeacherIdGet$Params } from '../fn/teacher/api-teacher-id-get';
import { apiTeacherIdGet$Plain } from '../fn/teacher/api-teacher-id-get-plain';
import { ApiTeacherIdGet$Plain$Params } from '../fn/teacher/api-teacher-id-get-plain';
import { apiTeacherIdPut } from '../fn/teacher/api-teacher-id-put';
import { ApiTeacherIdPut$Params } from '../fn/teacher/api-teacher-id-put';
import { apiTeacherPost } from '../fn/teacher/api-teacher-post';
import { ApiTeacherPost$Params } from '../fn/teacher/api-teacher-post';
import { TeacherRequest } from '../models/teacher-request';
import { TeacherRequestListPagination } from '../models/teacher-request-list-pagination';
import { ViewLevelDto } from '../models/view-level-dto';
import { ViewLevelYearsDto } from '../models/view-level-years-dto';
import { ViewSubjectDto } from '../models/view-subject-dto';
import { ViewTeacherDto } from '../models/view-teacher-dto';
import { ViewTeacherDtoIEnumerablePagination } from '../models/view-teacher-dto-i-enumerable-pagination';

@Injectable({ providedIn: 'root' })
export class TeacherService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiTeacherIdGet()` */
  static readonly ApiTeacherIdGetPath = '/api/Teacher/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherIdGet$Plain$Response(params: ApiTeacherIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewTeacherDto>> {
    return apiTeacherIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherIdGet$Plain(params: ApiTeacherIdGet$Plain$Params, context?: HttpContext): Observable<ViewTeacherDto> {
    return this.apiTeacherIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewTeacherDto>): ViewTeacherDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherIdGet$Response(params: ApiTeacherIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewTeacherDto>> {
    return apiTeacherIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherIdGet(params: ApiTeacherIdGet$Params, context?: HttpContext): Observable<ViewTeacherDto> {
    return this.apiTeacherIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewTeacherDto>): ViewTeacherDto => r.body)
    );
  }

  /** Path part for operation `apiTeacherIdPut()` */
  static readonly ApiTeacherIdPutPath = '/api/Teacher/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherIdPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherIdPut$Response(params: ApiTeacherIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiTeacherIdPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherIdPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherIdPut(params: ApiTeacherIdPut$Params, context?: HttpContext): Observable<void> {
    return this.apiTeacherIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiTeacherIdDelete()` */
  static readonly ApiTeacherIdDeletePath = '/api/Teacher/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherIdDelete$Response(params: ApiTeacherIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiTeacherIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherIdDelete(params: ApiTeacherIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiTeacherIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiTeacherGetLevelsByTeacherIdGet()` */
  static readonly ApiTeacherGetLevelsByTeacherIdGetPath = '/api/Teacher/getLevelsByTeacherId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetLevelsByTeacherIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGetLevelsByTeacherIdGet$Plain$Response(params?: ApiTeacherGetLevelsByTeacherIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewLevelDto>>> {
    return apiTeacherGetLevelsByTeacherIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetLevelsByTeacherIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGetLevelsByTeacherIdGet$Plain(params?: ApiTeacherGetLevelsByTeacherIdGet$Plain$Params, context?: HttpContext): Observable<Array<ViewLevelDto>> {
    return this.apiTeacherGetLevelsByTeacherIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewLevelDto>>): Array<ViewLevelDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetLevelsByTeacherIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGetLevelsByTeacherIdGet$Response(params?: ApiTeacherGetLevelsByTeacherIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewLevelDto>>> {
    return apiTeacherGetLevelsByTeacherIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetLevelsByTeacherIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGetLevelsByTeacherIdGet(params?: ApiTeacherGetLevelsByTeacherIdGet$Params, context?: HttpContext): Observable<Array<ViewLevelDto>> {
    return this.apiTeacherGetLevelsByTeacherIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewLevelDto>>): Array<ViewLevelDto> => r.body)
    );
  }

  /** Path part for operation `apiTeacherGetLevelYearsByTeacherIdGet()` */
  static readonly ApiTeacherGetLevelYearsByTeacherIdGetPath = '/api/Teacher/getLevelYearsByTeacherId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetLevelYearsByTeacherIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGetLevelYearsByTeacherIdGet$Plain$Response(params?: ApiTeacherGetLevelYearsByTeacherIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewLevelYearsDto>>> {
    return apiTeacherGetLevelYearsByTeacherIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetLevelYearsByTeacherIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGetLevelYearsByTeacherIdGet$Plain(params?: ApiTeacherGetLevelYearsByTeacherIdGet$Plain$Params, context?: HttpContext): Observable<Array<ViewLevelYearsDto>> {
    return this.apiTeacherGetLevelYearsByTeacherIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewLevelYearsDto>>): Array<ViewLevelYearsDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetLevelYearsByTeacherIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGetLevelYearsByTeacherIdGet$Response(params?: ApiTeacherGetLevelYearsByTeacherIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewLevelYearsDto>>> {
    return apiTeacherGetLevelYearsByTeacherIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetLevelYearsByTeacherIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGetLevelYearsByTeacherIdGet(params?: ApiTeacherGetLevelYearsByTeacherIdGet$Params, context?: HttpContext): Observable<Array<ViewLevelYearsDto>> {
    return this.apiTeacherGetLevelYearsByTeacherIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewLevelYearsDto>>): Array<ViewLevelYearsDto> => r.body)
    );
  }

  /** Path part for operation `apiTeacherGetSubjectsByTeacherIdGet()` */
  static readonly ApiTeacherGetSubjectsByTeacherIdGetPath = '/api/Teacher/getSubjectsByTeacherId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetSubjectsByTeacherIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGetSubjectsByTeacherIdGet$Plain$Response(params?: ApiTeacherGetSubjectsByTeacherIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewSubjectDto>>> {
    return apiTeacherGetSubjectsByTeacherIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetSubjectsByTeacherIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGetSubjectsByTeacherIdGet$Plain(params?: ApiTeacherGetSubjectsByTeacherIdGet$Plain$Params, context?: HttpContext): Observable<Array<ViewSubjectDto>> {
    return this.apiTeacherGetSubjectsByTeacherIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewSubjectDto>>): Array<ViewSubjectDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetSubjectsByTeacherIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGetSubjectsByTeacherIdGet$Response(params?: ApiTeacherGetSubjectsByTeacherIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewSubjectDto>>> {
    return apiTeacherGetSubjectsByTeacherIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetSubjectsByTeacherIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGetSubjectsByTeacherIdGet(params?: ApiTeacherGetSubjectsByTeacherIdGet$Params, context?: HttpContext): Observable<Array<ViewSubjectDto>> {
    return this.apiTeacherGetSubjectsByTeacherIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewSubjectDto>>): Array<ViewSubjectDto> => r.body)
    );
  }

  /** Path part for operation `apiTeacherChangeStatusPut()` */
  static readonly ApiTeacherChangeStatusPutPath = '/api/Teacher/ChangeStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherChangeStatusPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherChangeStatusPut$Response(params?: ApiTeacherChangeStatusPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiTeacherChangeStatusPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherChangeStatusPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherChangeStatusPut(params?: ApiTeacherChangeStatusPut$Params, context?: HttpContext): Observable<void> {
    return this.apiTeacherChangeStatusPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiTeacherGet()` */
  static readonly ApiTeacherGetPath = '/api/Teacher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGet$Plain$Response(params?: ApiTeacherGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewTeacherDtoIEnumerablePagination>> {
    return apiTeacherGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGet$Plain(params?: ApiTeacherGet$Plain$Params, context?: HttpContext): Observable<ViewTeacherDtoIEnumerablePagination> {
    return this.apiTeacherGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewTeacherDtoIEnumerablePagination>): ViewTeacherDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGet$Response(params?: ApiTeacherGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewTeacherDtoIEnumerablePagination>> {
    return apiTeacherGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGet(params?: ApiTeacherGet$Params, context?: HttpContext): Observable<ViewTeacherDtoIEnumerablePagination> {
    return this.apiTeacherGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewTeacherDtoIEnumerablePagination>): ViewTeacherDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiTeacherPost()` */
  static readonly ApiTeacherPostPath = '/api/Teacher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherPost$Response(params?: ApiTeacherPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiTeacherPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherPost(params?: ApiTeacherPost$Params, context?: HttpContext): Observable<void> {
    return this.apiTeacherPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiTeacherGetTeacherRequsetByIdGet()` */
  static readonly ApiTeacherGetTeacherRequsetByIdGetPath = '/api/Teacher/GetTeacherRequsetById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetTeacherRequsetByIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGetTeacherRequsetByIdGet$Plain$Response(params?: ApiTeacherGetTeacherRequsetByIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TeacherRequest>> {
    return apiTeacherGetTeacherRequsetByIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetTeacherRequsetByIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGetTeacherRequsetByIdGet$Plain(params?: ApiTeacherGetTeacherRequsetByIdGet$Plain$Params, context?: HttpContext): Observable<TeacherRequest> {
    return this.apiTeacherGetTeacherRequsetByIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TeacherRequest>): TeacherRequest => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetTeacherRequsetByIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGetTeacherRequsetByIdGet$Response(params?: ApiTeacherGetTeacherRequsetByIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<TeacherRequest>> {
    return apiTeacherGetTeacherRequsetByIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetTeacherRequsetByIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGetTeacherRequsetByIdGet(params?: ApiTeacherGetTeacherRequsetByIdGet$Params, context?: HttpContext): Observable<TeacherRequest> {
    return this.apiTeacherGetTeacherRequsetByIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<TeacherRequest>): TeacherRequest => r.body)
    );
  }

  /** Path part for operation `apiTeacherGetTeacherRequsetsGet()` */
  static readonly ApiTeacherGetTeacherRequsetsGetPath = '/api/Teacher/GetTeacherRequsets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetTeacherRequsetsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGetTeacherRequsetsGet$Plain$Response(params?: ApiTeacherGetTeacherRequsetsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TeacherRequestListPagination>> {
    return apiTeacherGetTeacherRequsetsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetTeacherRequsetsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGetTeacherRequsetsGet$Plain(params?: ApiTeacherGetTeacherRequsetsGet$Plain$Params, context?: HttpContext): Observable<TeacherRequestListPagination> {
    return this.apiTeacherGetTeacherRequsetsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TeacherRequestListPagination>): TeacherRequestListPagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetTeacherRequsetsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGetTeacherRequsetsGet$Response(params?: ApiTeacherGetTeacherRequsetsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<TeacherRequestListPagination>> {
    return apiTeacherGetTeacherRequsetsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetTeacherRequsetsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTeacherGetTeacherRequsetsGet(params?: ApiTeacherGetTeacherRequsetsGet$Params, context?: HttpContext): Observable<TeacherRequestListPagination> {
    return this.apiTeacherGetTeacherRequsetsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<TeacherRequestListPagination>): TeacherRequestListPagination => r.body)
    );
  }

  /** Path part for operation `apiTeacherAddTeacherRequsetsPost()` */
  static readonly ApiTeacherAddTeacherRequsetsPostPath = '/api/Teacher/AddTeacherRequsets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherAddTeacherRequsetsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherAddTeacherRequsetsPost$Response(params?: ApiTeacherAddTeacherRequsetsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiTeacherAddTeacherRequsetsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherAddTeacherRequsetsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherAddTeacherRequsetsPost(params?: ApiTeacherAddTeacherRequsetsPost$Params, context?: HttpContext): Observable<void> {
    return this.apiTeacherAddTeacherRequsetsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
