import { IParticlesProps } from '@tsparticles/angular';
import { MoveDirection, OutMode } from '@tsparticles/engine';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';
import Swal, { SweetAlertIcon, SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

export const EIDDESIGN = false;

export const bannerCarousel: OwlOptions = {
  loop: true,
  mouseDrag: true,
  center: false,
  touchDrag: true,
  pullDrag: true,
  dots: true,
  rtl: true,
  margin: 40,
  items: 3,
  autoWidth: true,
  navSpeed: 700,
  responsive: {
    0: {
      items: 1
    },
    400: {
      items: 1
    },
    740: {
      items: 2
    },
    940: {
      items: 3
    }
  },
  nav: false
}

export const projectName = {
  en: 'ILearn',
  ar: 'ILearn'
};



export const ROLES = {
  STUDENT: 'STUDENT',
  TEACHER: 'TEACHER',
  PARENT: 'PARENT',
  ADMIN: 'ADMIN',
}

export const logo = {
  light: '/assets/images/logo-light.png',
  dark: '/assets/images/logo-dark.png',
  light_sm: '/assets/images/logo-sm-light.svg',
  dark_sm: '/assets/images/logo-sm-dark.svg',

}



export const GetTimeInString = (timeInMinutes: number): string => {
  if (timeInMinutes < 0) {
    return "0 دقيقة";
  }

  const hours = Math.floor(timeInMinutes / 60);
  const minutes = timeInMinutes % 60;

  let hoursString = '';
  let minutesString = '';

  // Handle hours string
  if (hours > 0) {
    if (hours === 1) {
      hoursString = 'ساعة واحدة';
    } else if (hours === 2) {
      hoursString = 'ساعتان';
    } else if (hours <= 10) {
      hoursString = `${hours} ساعات`;
    } else {
      hoursString = `${hours} ساعة`;
    }
  }

  // Handle minutes string
  if (minutes > 0) {
    if (minutes === 1) {
      minutesString = 'دقيقة واحدة';
    } else if (minutes === 2) {
      minutesString = 'دقيقتان';
    } else if (minutes <= 10) {
      minutesString = `${minutes} دقائق`;
    } else {
      minutesString = `${minutes} دقيقة`;
    }
  }

  // Combine hours and minutes
  if (hours > 0 && minutes > 0) {
    return `${hoursString} و ${minutesString}`;
  } else if (hours > 0) {
    return hoursString;
  } else if (minutes > 0) {
    return minutesString;
  } else {
    return '٠ دقيقة';
  }
};


export function downloadData(data: BlobPart, contentDispositionHeader: string, contentType: string | null) {
  if (data && contentDispositionHeader && contentType) {
    const filenameRegex = /filename\*=UTF-8''([^;]+)/;
    const matches = filenameRegex.exec(contentDispositionHeader);

    let filename = 'download.zip';
    if (matches !== null && matches[1]) {
      filename = decodeURIComponent(matches[1].replace(/['"]/g, ''));
    }

    const blob = new Blob([data], { type: contentType || 'application/zip', endings: 'transparent' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;

    document.body.appendChild(link);
    link.click();

    // clean up the URL and link element
    URL.revokeObjectURL(link.href);
    document.body.removeChild(link);
  }
}


export function isSafeCoordinate(number: unknown): number | undefined {
  return number != '' && number != null && number != undefined ? +number : undefined
}
export const responseErrors = {
  DuplicateName: 'إسم مكرر مسبقًا',
  Error: 'حدث خطأ',
  CompanyTypeNotEqualsProjectType: 'الشركة ونوع المشروع غير متطابقين',
  LOCKED: 'تم إيقاف حسابك مؤقتًا .. برجاء التواصل مع مدير النظام',
  'The Name field is required.': 'يجب إدخــال الإسم',
  'The projectCommand field is required.': 'بعض المدخلات الإجبارية غير موجودة',
  FieldRequired: 'برجاء ملء كل البيانات بشكل صحيح',
  InvalidCRN: 'رقم سجل تجاري غير صحيح',
  MaxFieldLength: 'عدد الحروف أكثر من المطلوب',
  MinFieldLength: 'عدد الحروف أقل من المطلوب',
  UserNotFound: 'اسم المستخدم غير موجود',
  InvalidLogin: 'اسم المستخدم او كلمة المرور خطأ ',
  DuplicateUser: 'المستخدم موجود من قبل',
  DuplicateEmail: 'تم تسجيل حساب بالفعل بهذا البريد الإلكتروني',
  DuplicatePhoneNumber: 'هناك حساب بالفعل يستخدم نفس رقم التليفون',
  NotFound: 'لا يوجد بيانات',
  CantDelete: 'لا يمكن الحــذف',
  DuplicateCode: 'الكود مُستخدم من قبــل',
  UserAlreadyLoggedIn: 'أنت بالفعل مسجل دخولك',
  InvalidEmailAddress: 'البريد الإلكتروني غير صحيح',
  InvalidPhoneNumber: 'رقم التليفون غير صحيح',
  Expired: 'انتهت صلاحية رمز التاكيد',
  CantLockYourself: 'لا يمكنك إيقاف حسابك',

  DuplicateCrn: 'رقم تجاري مكرر',

  InvalidUsername: 'اسم مستخدم غير مقبول',
  InvalidCrn: 'رقم تجاري غير صالح',
  InvalidPasswordRequirements: 'كلمة مرور غير صالحة',
  DuplicateIdNumber: 'Id مكرر',
  InvalidStartDate: 'تاريخ بداية غير صالح',
  InvalidEndDate: 'تاريخ نهاية غير صالح',
  InvalidDate: 'تاريخ غير صالح',
  ParentNotFound: 'حدث خطأ',
  ProjectNotFound: 'المشروع غير موجود',
  CantLockAdmin: 'لا تستطيع إيقاف حساب مدير النظام',
  ExceedValue: 'القيمة أكثر من المطلوب',
  CategoryNotFound: 'الفئة غير موجودة',
  FileNotFound: 'الملف غير موجود',
  BadFile: 'ملف  غير صالح',
  AccessDenied: 'لا تمتلك صلاحية',
  LastValueIsBiggerThanCurrentValue: 'القيمة الأخيرة يجب أن تكون أكبر من القيمة الحالية',
  CantCreateUser: 'حدث خطأ في إنشاء مستخدم',
  TimeOut: 'انتهي الوقــت',
  UnAuthorize: 'غير مصرح بالتنفيذ'
}


export const errorCallback = (err: any) => { SWAL('error', err?.errorCode) }

export const SWAL = (type: SweetAlertIcon, title: string, text?: string
  , confirmCallback?: Function,
  options?: SweetAlertOptions): Promise<SweetAlertResult<any>> | null => {
  if (title === 'NO-ERROR') {
    return null;
  }
  let errmsg = title ? responseErrors[title] || title : title;

  return Swal.fire({
    icon: type,
    titleText: errmsg || (type === 'error' ? 'حدث خطأ' : ''),
    text,
    confirmButtonColor: '#2BA18Cff',
    confirmButtonText: 'تمام',
    cancelButtonText: 'إلغاء',
    ...options,

  })
}
export const SWALConfirmation = (
  type: SweetAlertIcon,
  title?: string,
  callableFunction?: Observable<any>,
  resultText?: string,
  actionText?: string,

): Promise<SweetAlertResult<any>> => {

  return Swal.fire({
    title: title,
    icon: type,
    showCancelButton: true,
    confirmButtonText: actionText || 'نعـم',
    cancelButtonText: 'إغلاق',
    showLoaderOnConfirm: true,
    confirmButtonColor: '#f46a6a',
    preConfirm: isConfirmed => {
      // eslint-disable-next-line no-unused-vars
      return new Promise<boolean>((resolve, reject) => {
        if (isConfirmed) {
          callableFunction?.subscribe({
            next: (res) => {
              SWAL('success', resultText || 'تم التنفيذ');

              resolve(true)

            },
            error: (res) => {
              SWAL('error', res);
              reject(false)
            }
          })
        }
      });
    },
    allowOutsideClick: true
  })

}


export function SWAL_Celebrate(title?: string | null, text?: string | null, iconHtml?: string | null, backdrop?: string | null, confirmButtonText?: string) {
  return Swal.fire({
    title: title || "مبروووك",
    text: text || "",
    icon: 'success',
    iconHtml: iconHtml || '<img height="100" src="/assets/images/prize_1.png"/>',
    backdrop: backdrop || `
    rgba(0,0,0,0.2)
    url("/assets/images/celebration.gif")
    center top
    repeat
  `,
    confirmButtonText: confirmButtonText || 'تمام'
  });
}


export const userNamePattern = '^(?=[a-zA-Z0-9._]{3,20}$)(?!.*[_.]{2})[^_.].*[^_.]$'
export const numberMoreThanZeroPattern = /^[1-9]\d*(\.\d+)?$/
export const mustNumberPattern = /^-?\d*(\.\d+)?$/


export const particlesOptions: IParticlesProps = {
  background: {
    color: {
      value: "transparent",
    },
  },
  fpsLimit: 120,
  styles: [
    ''
  ],
  interactivity: {
    events: {

      onClick: {
        enable: true,
        mode: "push",
      },
      onHover: {
        enable: false,
        mode: 'repulse',
      },
      resize: {
        enable: false,
      },
    },
    modes: {
      push: {
        quantity: 1,
      },
      repulse: {
        distance: 300,
        duration: 0.7,
      },
    },
  },
  particles: {
    color: {
      value: ["#ffaf00", "#FF6056",],
    },
    move: {
      direction: MoveDirection.none,
      enable: true,
      outModes: {
        default: OutMode.out,
      },
      random: true,
      "speed": {
        "min": 1,
        "max": 2
      },
      straight: false,

    },
    number: {
      density: {
        enable: false,
      },
      value: 10,
      limit: {
        mode: "delete",
        value: 20,

      }
    },
    opacity: {
      value: 1,
    },
    shape: {
      type: ["image"],
      options: {
        image: [
          {
            src: '/assets/images/icons_edu/(1).svg',
          },
          {
            src: '/assets/images/icons_edu/(2).svg',
          },
          {
            src: '/assets/images/icons_edu/(3).svg',
          },
          {
            src: '/assets/images/icons_edu/(3).svg',
          },
          {
            src: '/assets/images/icons_edu/(3).svg',
          },
          {
            src: '/assets/images/icons_edu/(3).svg',
          },
          {
            src: '/assets/images/icons_edu/(4).svg',
          },
          {
            src: '/assets/images/icons_edu/(5).svg',
          },
          {
            src: '/assets/images/icons_edu/(6).svg',
          },
          {
            src: '/assets/images/icons_edu/(7).svg',
          },
          {
            src: '/assets/images/icons_edu/(8).svg',
          },
          {
            src: '/assets/images/icons_edu/(9).svg',
          },
          {
            src: '/assets/images/icons_edu/(10).svg',
          },
          {
            src: '/assets/images/icons_edu/(11).svg',
          },
          {
            src: '/assets/images/icons_edu/(12).svg',
          },
        ]
      },
    },
    size: {
      value: { min: 15, max: 30 },
    },
  },
  detectRetina: false,
  fullScreen: true,
  zLayers: 1
};
