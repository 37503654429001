/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiCourseAcceptStudentPut } from '../fn/course/api-course-accept-student-put';
import { ApiCourseAcceptStudentPut$Params } from '../fn/course/api-course-accept-student-put';
import { apiCourseChangeStatusPut } from '../fn/course/api-course-change-status-put';
import { ApiCourseChangeStatusPut$Params } from '../fn/course/api-course-change-status-put';
import { apiCourseGet } from '../fn/course/api-course-get';
import { ApiCourseGet$Params } from '../fn/course/api-course-get';
import { apiCourseGet$Plain } from '../fn/course/api-course-get-plain';
import { ApiCourseGet$Plain$Params } from '../fn/course/api-course-get-plain';
import { apiCourseGetAcceptedStudentGet } from '../fn/course/api-course-get-accepted-student-get';
import { ApiCourseGetAcceptedStudentGet$Params } from '../fn/course/api-course-get-accepted-student-get';
import { apiCourseGetAcceptedStudentGet$Plain } from '../fn/course/api-course-get-accepted-student-get-plain';
import { ApiCourseGetAcceptedStudentGet$Plain$Params } from '../fn/course/api-course-get-accepted-student-get-plain';
import { apiCourseGetPinnedStudentGet } from '../fn/course/api-course-get-pinned-student-get';
import { ApiCourseGetPinnedStudentGet$Params } from '../fn/course/api-course-get-pinned-student-get';
import { apiCourseGetPinnedStudentGet$Plain } from '../fn/course/api-course-get-pinned-student-get-plain';
import { ApiCourseGetPinnedStudentGet$Plain$Params } from '../fn/course/api-course-get-pinned-student-get-plain';
import { apiCourseGetTopCourseGet } from '../fn/course/api-course-get-top-course-get';
import { ApiCourseGetTopCourseGet$Params } from '../fn/course/api-course-get-top-course-get';
import { apiCourseGetTopCourseGet$Plain } from '../fn/course/api-course-get-top-course-get-plain';
import { ApiCourseGetTopCourseGet$Plain$Params } from '../fn/course/api-course-get-top-course-get-plain';
import { apiCourseIdDelete } from '../fn/course/api-course-id-delete';
import { ApiCourseIdDelete$Params } from '../fn/course/api-course-id-delete';
import { apiCourseIdGet } from '../fn/course/api-course-id-get';
import { ApiCourseIdGet$Params } from '../fn/course/api-course-id-get';
import { apiCourseIdGet$Plain } from '../fn/course/api-course-id-get-plain';
import { ApiCourseIdGet$Plain$Params } from '../fn/course/api-course-id-get-plain';
import { apiCourseIdPut } from '../fn/course/api-course-id-put';
import { ApiCourseIdPut$Params } from '../fn/course/api-course-id-put';
import { apiCoursePost } from '../fn/course/api-course-post';
import { ApiCoursePost$Params } from '../fn/course/api-course-post';
import { apiCourseRejectStudentDelete } from '../fn/course/api-course-reject-student-delete';
import { ApiCourseRejectStudentDelete$Params } from '../fn/course/api-course-reject-student-delete';
import { ViewCourseDto } from '../models/view-course-dto';
import { ViewCourseDtoIEnumerablePagination } from '../models/view-course-dto-i-enumerable-pagination';
import { ViewStudentCourseDtoIEnumerablePagination } from '../models/view-student-course-dto-i-enumerable-pagination';
import { ViewStudentDtoIEnumerablePagination } from '../models/view-student-dto-i-enumerable-pagination';

@Injectable({ providedIn: 'root' })
export class CourseService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiCourseGet()` */
  static readonly ApiCourseGetPath = '/api/Course';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseGet$Plain$Response(params?: ApiCourseGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewCourseDtoIEnumerablePagination>> {
    return apiCourseGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseGet$Plain(params?: ApiCourseGet$Plain$Params, context?: HttpContext): Observable<ViewCourseDtoIEnumerablePagination> {
    return this.apiCourseGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewCourseDtoIEnumerablePagination>): ViewCourseDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseGet$Response(params?: ApiCourseGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewCourseDtoIEnumerablePagination>> {
    return apiCourseGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseGet(params?: ApiCourseGet$Params, context?: HttpContext): Observable<ViewCourseDtoIEnumerablePagination> {
    return this.apiCourseGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewCourseDtoIEnumerablePagination>): ViewCourseDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiCoursePost()` */
  static readonly ApiCoursePostPath = '/api/Course';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCoursePost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCoursePost$Response(params?: ApiCoursePost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiCoursePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCoursePost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCoursePost(params?: ApiCoursePost$Params, context?: HttpContext): Observable<void> {
    return this.apiCoursePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiCourseGetTopCourseGet()` */
  static readonly ApiCourseGetTopCourseGetPath = '/api/Course/getTopCourse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseGetTopCourseGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseGetTopCourseGet$Plain$Response(params?: ApiCourseGetTopCourseGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewCourseDto>>> {
    return apiCourseGetTopCourseGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseGetTopCourseGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseGetTopCourseGet$Plain(params?: ApiCourseGetTopCourseGet$Plain$Params, context?: HttpContext): Observable<Array<ViewCourseDto>> {
    return this.apiCourseGetTopCourseGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewCourseDto>>): Array<ViewCourseDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseGetTopCourseGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseGetTopCourseGet$Response(params?: ApiCourseGetTopCourseGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewCourseDto>>> {
    return apiCourseGetTopCourseGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseGetTopCourseGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseGetTopCourseGet(params?: ApiCourseGetTopCourseGet$Params, context?: HttpContext): Observable<Array<ViewCourseDto>> {
    return this.apiCourseGetTopCourseGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewCourseDto>>): Array<ViewCourseDto> => r.body)
    );
  }

  /** Path part for operation `apiCourseGetAcceptedStudentGet()` */
  static readonly ApiCourseGetAcceptedStudentGetPath = '/api/Course/getAcceptedStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseGetAcceptedStudentGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseGetAcceptedStudentGet$Plain$Response(params?: ApiCourseGetAcceptedStudentGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentDtoIEnumerablePagination>> {
    return apiCourseGetAcceptedStudentGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseGetAcceptedStudentGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseGetAcceptedStudentGet$Plain(params?: ApiCourseGetAcceptedStudentGet$Plain$Params, context?: HttpContext): Observable<ViewStudentDtoIEnumerablePagination> {
    return this.apiCourseGetAcceptedStudentGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentDtoIEnumerablePagination>): ViewStudentDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseGetAcceptedStudentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseGetAcceptedStudentGet$Response(params?: ApiCourseGetAcceptedStudentGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentDtoIEnumerablePagination>> {
    return apiCourseGetAcceptedStudentGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseGetAcceptedStudentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseGetAcceptedStudentGet(params?: ApiCourseGetAcceptedStudentGet$Params, context?: HttpContext): Observable<ViewStudentDtoIEnumerablePagination> {
    return this.apiCourseGetAcceptedStudentGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentDtoIEnumerablePagination>): ViewStudentDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiCourseChangeStatusPut()` */
  static readonly ApiCourseChangeStatusPutPath = '/api/Course/ChangeStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseChangeStatusPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseChangeStatusPut$Response(params?: ApiCourseChangeStatusPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiCourseChangeStatusPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseChangeStatusPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseChangeStatusPut(params?: ApiCourseChangeStatusPut$Params, context?: HttpContext): Observable<void> {
    return this.apiCourseChangeStatusPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiCourseGetPinnedStudentGet()` */
  static readonly ApiCourseGetPinnedStudentGetPath = '/api/Course/getPinnedStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseGetPinnedStudentGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseGetPinnedStudentGet$Plain$Response(params?: ApiCourseGetPinnedStudentGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentCourseDtoIEnumerablePagination>> {
    return apiCourseGetPinnedStudentGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseGetPinnedStudentGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseGetPinnedStudentGet$Plain(params?: ApiCourseGetPinnedStudentGet$Plain$Params, context?: HttpContext): Observable<ViewStudentCourseDtoIEnumerablePagination> {
    return this.apiCourseGetPinnedStudentGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentCourseDtoIEnumerablePagination>): ViewStudentCourseDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseGetPinnedStudentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseGetPinnedStudentGet$Response(params?: ApiCourseGetPinnedStudentGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentCourseDtoIEnumerablePagination>> {
    return apiCourseGetPinnedStudentGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseGetPinnedStudentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseGetPinnedStudentGet(params?: ApiCourseGetPinnedStudentGet$Params, context?: HttpContext): Observable<ViewStudentCourseDtoIEnumerablePagination> {
    return this.apiCourseGetPinnedStudentGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentCourseDtoIEnumerablePagination>): ViewStudentCourseDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiCourseIdGet()` */
  static readonly ApiCourseIdGetPath = '/api/Course/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseIdGet$Plain$Response(params: ApiCourseIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewCourseDto>> {
    return apiCourseIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseIdGet$Plain(params: ApiCourseIdGet$Plain$Params, context?: HttpContext): Observable<ViewCourseDto> {
    return this.apiCourseIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewCourseDto>): ViewCourseDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseIdGet$Response(params: ApiCourseIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewCourseDto>> {
    return apiCourseIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseIdGet(params: ApiCourseIdGet$Params, context?: HttpContext): Observable<ViewCourseDto> {
    return this.apiCourseIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewCourseDto>): ViewCourseDto => r.body)
    );
  }

  /** Path part for operation `apiCourseIdPut()` */
  static readonly ApiCourseIdPutPath = '/api/Course/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseIdPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCourseIdPut$Response(params: ApiCourseIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiCourseIdPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseIdPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCourseIdPut(params: ApiCourseIdPut$Params, context?: HttpContext): Observable<void> {
    return this.apiCourseIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiCourseIdDelete()` */
  static readonly ApiCourseIdDeletePath = '/api/Course/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseIdDelete$Response(params: ApiCourseIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiCourseIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCourseIdDelete(params: ApiCourseIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiCourseIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiCourseAcceptStudentPut()` */
  static readonly ApiCourseAcceptStudentPutPath = '/api/Course/AcceptStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseAcceptStudentPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCourseAcceptStudentPut$Response(params?: ApiCourseAcceptStudentPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiCourseAcceptStudentPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseAcceptStudentPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCourseAcceptStudentPut(params?: ApiCourseAcceptStudentPut$Params, context?: HttpContext): Observable<void> {
    return this.apiCourseAcceptStudentPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiCourseRejectStudentDelete()` */
  static readonly ApiCourseRejectStudentDeletePath = '/api/Course/RejectStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseRejectStudentDelete()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCourseRejectStudentDelete$Response(params?: ApiCourseRejectStudentDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiCourseRejectStudentDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseRejectStudentDelete$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCourseRejectStudentDelete(params?: ApiCourseRejectStudentDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiCourseRejectStudentDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
