/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiBannerGet } from '../fn/banner/api-banner-get';
import { ApiBannerGet$Params } from '../fn/banner/api-banner-get';
import { apiBannerGet$Plain } from '../fn/banner/api-banner-get-plain';
import { ApiBannerGet$Plain$Params } from '../fn/banner/api-banner-get-plain';
import { apiBannerIdDelete } from '../fn/banner/api-banner-id-delete';
import { ApiBannerIdDelete$Params } from '../fn/banner/api-banner-id-delete';
import { apiBannerIdGet } from '../fn/banner/api-banner-id-get';
import { ApiBannerIdGet$Params } from '../fn/banner/api-banner-id-get';
import { apiBannerIdGet$Plain } from '../fn/banner/api-banner-id-get-plain';
import { ApiBannerIdGet$Plain$Params } from '../fn/banner/api-banner-id-get-plain';
import { apiBannerIdPut } from '../fn/banner/api-banner-id-put';
import { ApiBannerIdPut$Params } from '../fn/banner/api-banner-id-put';
import { apiBannerPost } from '../fn/banner/api-banner-post';
import { ApiBannerPost$Params } from '../fn/banner/api-banner-post';
import { ViewBannerDto } from '../models/view-banner-dto';
import { ViewBannerDtoIEnumerablePagination } from '../models/view-banner-dto-i-enumerable-pagination';

@Injectable({ providedIn: 'root' })
export class BannerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiBannerGet()` */
  static readonly ApiBannerGetPath = '/api/Banner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBannerGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerGet$Plain$Response(params?: ApiBannerGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewBannerDtoIEnumerablePagination>> {
    return apiBannerGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBannerGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerGet$Plain(params?: ApiBannerGet$Plain$Params, context?: HttpContext): Observable<ViewBannerDtoIEnumerablePagination> {
    return this.apiBannerGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewBannerDtoIEnumerablePagination>): ViewBannerDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBannerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerGet$Response(params?: ApiBannerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewBannerDtoIEnumerablePagination>> {
    return apiBannerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBannerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerGet(params?: ApiBannerGet$Params, context?: HttpContext): Observable<ViewBannerDtoIEnumerablePagination> {
    return this.apiBannerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewBannerDtoIEnumerablePagination>): ViewBannerDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiBannerPost()` */
  static readonly ApiBannerPostPath = '/api/Banner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBannerPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiBannerPost$Response(params?: ApiBannerPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiBannerPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBannerPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiBannerPost(params?: ApiBannerPost$Params, context?: HttpContext): Observable<void> {
    return this.apiBannerPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiBannerIdGet()` */
  static readonly ApiBannerIdGetPath = '/api/Banner/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBannerIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerIdGet$Plain$Response(params: ApiBannerIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewBannerDto>> {
    return apiBannerIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBannerIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerIdGet$Plain(params: ApiBannerIdGet$Plain$Params, context?: HttpContext): Observable<ViewBannerDto> {
    return this.apiBannerIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewBannerDto>): ViewBannerDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBannerIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerIdGet$Response(params: ApiBannerIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewBannerDto>> {
    return apiBannerIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBannerIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerIdGet(params: ApiBannerIdGet$Params, context?: HttpContext): Observable<ViewBannerDto> {
    return this.apiBannerIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewBannerDto>): ViewBannerDto => r.body)
    );
  }

  /** Path part for operation `apiBannerIdPut()` */
  static readonly ApiBannerIdPutPath = '/api/Banner/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBannerIdPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiBannerIdPut$Response(params: ApiBannerIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiBannerIdPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBannerIdPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiBannerIdPut(params: ApiBannerIdPut$Params, context?: HttpContext): Observable<void> {
    return this.apiBannerIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiBannerIdDelete()` */
  static readonly ApiBannerIdDeletePath = '/api/Banner/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBannerIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerIdDelete$Response(params: ApiBannerIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiBannerIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBannerIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerIdDelete(params: ApiBannerIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiBannerIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
