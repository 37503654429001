/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiParentAcceptParentStudentPost } from '../fn/parent/api-parent-accept-parent-student-post';
import { ApiParentAcceptParentStudentPost$Params } from '../fn/parent/api-parent-accept-parent-student-post';
import { apiParentAddParentStudentRequestPost } from '../fn/parent/api-parent-add-parent-student-request-post';
import { ApiParentAddParentStudentRequestPost$Params } from '../fn/parent/api-parent-add-parent-student-request-post';
import { apiParentGetParentRequestsForParentGet } from '../fn/parent/api-parent-get-parent-requests-for-parent-get';
import { ApiParentGetParentRequestsForParentGet$Params } from '../fn/parent/api-parent-get-parent-requests-for-parent-get';
import { apiParentGetParentRequestsForParentGet$Plain } from '../fn/parent/api-parent-get-parent-requests-for-parent-get-plain';
import { ApiParentGetParentRequestsForParentGet$Plain$Params } from '../fn/parent/api-parent-get-parent-requests-for-parent-get-plain';
import { apiParentGetStudentByCodeParentGet } from '../fn/parent/api-parent-get-student-by-code-parent-get';
import { ApiParentGetStudentByCodeParentGet$Params } from '../fn/parent/api-parent-get-student-by-code-parent-get';
import { apiParentGetStudentByCodeParentGet$Plain } from '../fn/parent/api-parent-get-student-by-code-parent-get-plain';
import { ApiParentGetStudentByCodeParentGet$Plain$Params } from '../fn/parent/api-parent-get-student-by-code-parent-get-plain';
import { apiParentGetStudentByIdGet } from '../fn/parent/api-parent-get-student-by-id-get';
import { ApiParentGetStudentByIdGet$Params } from '../fn/parent/api-parent-get-student-by-id-get';
import { apiParentGetStudentByIdGet$Plain } from '../fn/parent/api-parent-get-student-by-id-get-plain';
import { ApiParentGetStudentByIdGet$Plain$Params } from '../fn/parent/api-parent-get-student-by-id-get-plain';
import { apiParentGetStudentDashboardDataGet } from '../fn/parent/api-parent-get-student-dashboard-data-get';
import { ApiParentGetStudentDashboardDataGet$Params } from '../fn/parent/api-parent-get-student-dashboard-data-get';
import { apiParentGetStudentDashboardDataGet$Plain } from '../fn/parent/api-parent-get-student-dashboard-data-get-plain';
import { ApiParentGetStudentDashboardDataGet$Plain$Params } from '../fn/parent/api-parent-get-student-dashboard-data-get-plain';
import { apiParentGetStudentGet } from '../fn/parent/api-parent-get-student-get';
import { ApiParentGetStudentGet$Params } from '../fn/parent/api-parent-get-student-get';
import { apiParentGetStudentGet$Plain } from '../fn/parent/api-parent-get-student-get-plain';
import { ApiParentGetStudentGet$Plain$Params } from '../fn/parent/api-parent-get-student-get-plain';
import { apiParentRegisterParentPost } from '../fn/parent/api-parent-register-parent-post';
import { ApiParentRegisterParentPost$Params } from '../fn/parent/api-parent-register-parent-post';
import { apiParentRegisterParentPost$Plain } from '../fn/parent/api-parent-register-parent-post-plain';
import { ApiParentRegisterParentPost$Plain$Params } from '../fn/parent/api-parent-register-parent-post-plain';
import { ApplicationUser } from '../models/application-user';
import { StudentDashboardDataDto } from '../models/student-dashboard-data-dto';
import { UserDto } from '../models/user-dto';
import { ViewStudentDto } from '../models/view-student-dto';
import { ViewStudentParentDto } from '../models/view-student-parent-dto';

@Injectable({ providedIn: 'root' })
export class ParentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiParentRegisterParentPost()` */
  static readonly ApiParentRegisterParentPostPath = '/api/Parent/RegisterParent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiParentRegisterParentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiParentRegisterParentPost$Plain$Response(params?: ApiParentRegisterParentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ApplicationUser>> {
    return apiParentRegisterParentPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiParentRegisterParentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiParentRegisterParentPost$Plain(params?: ApiParentRegisterParentPost$Plain$Params, context?: HttpContext): Observable<ApplicationUser> {
    return this.apiParentRegisterParentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationUser>): ApplicationUser => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiParentRegisterParentPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiParentRegisterParentPost$Response(params?: ApiParentRegisterParentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ApplicationUser>> {
    return apiParentRegisterParentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiParentRegisterParentPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiParentRegisterParentPost(params?: ApiParentRegisterParentPost$Params, context?: HttpContext): Observable<ApplicationUser> {
    return this.apiParentRegisterParentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationUser>): ApplicationUser => r.body)
    );
  }

  /** Path part for operation `apiParentAddParentStudentRequestPost()` */
  static readonly ApiParentAddParentStudentRequestPostPath = '/api/Parent/AddParentStudentRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiParentAddParentStudentRequestPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentAddParentStudentRequestPost$Response(params?: ApiParentAddParentStudentRequestPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiParentAddParentStudentRequestPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiParentAddParentStudentRequestPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentAddParentStudentRequestPost(params?: ApiParentAddParentStudentRequestPost$Params, context?: HttpContext): Observable<void> {
    return this.apiParentAddParentStudentRequestPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiParentAcceptParentStudentPost()` */
  static readonly ApiParentAcceptParentStudentPostPath = '/api/Parent/AcceptParentStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiParentAcceptParentStudentPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentAcceptParentStudentPost$Response(params?: ApiParentAcceptParentStudentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiParentAcceptParentStudentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiParentAcceptParentStudentPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentAcceptParentStudentPost(params?: ApiParentAcceptParentStudentPost$Params, context?: HttpContext): Observable<void> {
    return this.apiParentAcceptParentStudentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiParentGetParentRequestsForParentGet()` */
  static readonly ApiParentGetParentRequestsForParentGetPath = '/api/Parent/GetParentRequestsForParent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiParentGetParentRequestsForParentGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentGetParentRequestsForParentGet$Plain$Response(params?: ApiParentGetParentRequestsForParentGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserDto>>> {
    return apiParentGetParentRequestsForParentGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiParentGetParentRequestsForParentGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentGetParentRequestsForParentGet$Plain(params?: ApiParentGetParentRequestsForParentGet$Plain$Params, context?: HttpContext): Observable<Array<UserDto>> {
    return this.apiParentGetParentRequestsForParentGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>): Array<UserDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiParentGetParentRequestsForParentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentGetParentRequestsForParentGet$Response(params?: ApiParentGetParentRequestsForParentGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserDto>>> {
    return apiParentGetParentRequestsForParentGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiParentGetParentRequestsForParentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentGetParentRequestsForParentGet(params?: ApiParentGetParentRequestsForParentGet$Params, context?: HttpContext): Observable<Array<UserDto>> {
    return this.apiParentGetParentRequestsForParentGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>): Array<UserDto> => r.body)
    );
  }

  /** Path part for operation `apiParentGetStudentGet()` */
  static readonly ApiParentGetStudentGetPath = '/api/Parent/GetStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiParentGetStudentGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentGetStudentGet$Plain$Response(params?: ApiParentGetStudentGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewStudentDto>>> {
    return apiParentGetStudentGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiParentGetStudentGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentGetStudentGet$Plain(params?: ApiParentGetStudentGet$Plain$Params, context?: HttpContext): Observable<Array<ViewStudentDto>> {
    return this.apiParentGetStudentGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewStudentDto>>): Array<ViewStudentDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiParentGetStudentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentGetStudentGet$Response(params?: ApiParentGetStudentGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewStudentDto>>> {
    return apiParentGetStudentGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiParentGetStudentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentGetStudentGet(params?: ApiParentGetStudentGet$Params, context?: HttpContext): Observable<Array<ViewStudentDto>> {
    return this.apiParentGetStudentGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewStudentDto>>): Array<ViewStudentDto> => r.body)
    );
  }

  /** Path part for operation `apiParentGetStudentDashboardDataGet()` */
  static readonly ApiParentGetStudentDashboardDataGetPath = '/api/Parent/GetStudentDashboardData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiParentGetStudentDashboardDataGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentGetStudentDashboardDataGet$Plain$Response(params?: ApiParentGetStudentDashboardDataGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StudentDashboardDataDto>> {
    return apiParentGetStudentDashboardDataGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiParentGetStudentDashboardDataGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentGetStudentDashboardDataGet$Plain(params?: ApiParentGetStudentDashboardDataGet$Plain$Params, context?: HttpContext): Observable<StudentDashboardDataDto> {
    return this.apiParentGetStudentDashboardDataGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StudentDashboardDataDto>): StudentDashboardDataDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiParentGetStudentDashboardDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentGetStudentDashboardDataGet$Response(params?: ApiParentGetStudentDashboardDataGet$Params, context?: HttpContext): Observable<StrictHttpResponse<StudentDashboardDataDto>> {
    return apiParentGetStudentDashboardDataGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiParentGetStudentDashboardDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentGetStudentDashboardDataGet(params?: ApiParentGetStudentDashboardDataGet$Params, context?: HttpContext): Observable<StudentDashboardDataDto> {
    return this.apiParentGetStudentDashboardDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<StudentDashboardDataDto>): StudentDashboardDataDto => r.body)
    );
  }

  /** Path part for operation `apiParentGetStudentByIdGet()` */
  static readonly ApiParentGetStudentByIdGetPath = '/api/Parent/getStudentById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiParentGetStudentByIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentGetStudentByIdGet$Plain$Response(params?: ApiParentGetStudentByIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentParentDto>> {
    return apiParentGetStudentByIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiParentGetStudentByIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentGetStudentByIdGet$Plain(params?: ApiParentGetStudentByIdGet$Plain$Params, context?: HttpContext): Observable<ViewStudentParentDto> {
    return this.apiParentGetStudentByIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentParentDto>): ViewStudentParentDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiParentGetStudentByIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentGetStudentByIdGet$Response(params?: ApiParentGetStudentByIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentParentDto>> {
    return apiParentGetStudentByIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiParentGetStudentByIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentGetStudentByIdGet(params?: ApiParentGetStudentByIdGet$Params, context?: HttpContext): Observable<ViewStudentParentDto> {
    return this.apiParentGetStudentByIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentParentDto>): ViewStudentParentDto => r.body)
    );
  }

  /** Path part for operation `apiParentGetStudentByCodeParentGet()` */
  static readonly ApiParentGetStudentByCodeParentGetPath = '/api/Parent/GetStudentByCodeParent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiParentGetStudentByCodeParentGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentGetStudentByCodeParentGet$Plain$Response(params?: ApiParentGetStudentByCodeParentGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentDto>> {
    return apiParentGetStudentByCodeParentGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiParentGetStudentByCodeParentGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentGetStudentByCodeParentGet$Plain(params?: ApiParentGetStudentByCodeParentGet$Plain$Params, context?: HttpContext): Observable<ViewStudentDto> {
    return this.apiParentGetStudentByCodeParentGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentDto>): ViewStudentDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiParentGetStudentByCodeParentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentGetStudentByCodeParentGet$Response(params?: ApiParentGetStudentByCodeParentGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentDto>> {
    return apiParentGetStudentByCodeParentGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiParentGetStudentByCodeParentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentGetStudentByCodeParentGet(params?: ApiParentGetStudentByCodeParentGet$Params, context?: HttpContext): Observable<ViewStudentDto> {
    return this.apiParentGetStudentByCodeParentGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentDto>): ViewStudentDto => r.body)
    );
  }

}
