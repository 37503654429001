<div *ngIf="acc">
  <div class="dropdown  d-block" display="dynamic" [container]="'body'" ngbDropdown>
    <a style="grid-auto-flow: column;" type="button" class="text-gray-700 d-grid align-items-center fw-bold p-0"
      ngbDropdownToggle id="page-header-user-dropdown">
      <span class="notif_circle me-1">
        <span class="isax-profile text-primary fs-5">

        </span>
      </span>
      <div class="d-none text-truncate text d-xl-inline-block small ms-1" [ngClass]="{'text-white': light}">
        <p class="text-truncate mb-0">
          {{acc?.fullName	 || acc.userName}}
        </p>
      </div>
      <i class=" text-primary ms-1  isax-arrow-bottom d-none d-xl-inline-block"></i>
    </a>
    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
      <!-- item-->
      <!-- <a class="dropdown-item" href="javascript: void(0);" routerLink="/account-setting"><i
          class="bx bx-cog  align-middle me-1 "></i>
        إعدادات الحســاب
      </a> -->
      <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
          class="bx bx-power-off  align-middle me-1 text-danger"></i>
        تسجيل الخروج
      </a>
    </div>
  </div>

</div>