/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiNotificationGetAdminNotifcationsGet } from '../fn/notification/api-notification-get-admin-notifcations-get';
import { ApiNotificationGetAdminNotifcationsGet$Params } from '../fn/notification/api-notification-get-admin-notifcations-get';
import { apiNotificationGetAdminNotifcationsGet$Plain } from '../fn/notification/api-notification-get-admin-notifcations-get-plain';
import { ApiNotificationGetAdminNotifcationsGet$Plain$Params } from '../fn/notification/api-notification-get-admin-notifcations-get-plain';
import { apiNotificationGetStudentNotifcationsGet } from '../fn/notification/api-notification-get-student-notifcations-get';
import { ApiNotificationGetStudentNotifcationsGet$Params } from '../fn/notification/api-notification-get-student-notifcations-get';
import { apiNotificationGetStudentNotifcationsGet$Plain } from '../fn/notification/api-notification-get-student-notifcations-get-plain';
import { ApiNotificationGetStudentNotifcationsGet$Plain$Params } from '../fn/notification/api-notification-get-student-notifcations-get-plain';
import { apiNotificationReadAllNotificationPut } from '../fn/notification/api-notification-read-all-notification-put';
import { ApiNotificationReadAllNotificationPut$Params } from '../fn/notification/api-notification-read-all-notification-put';
import { apiNotificationReadAllNotificationPut$Plain } from '../fn/notification/api-notification-read-all-notification-put-plain';
import { ApiNotificationReadAllNotificationPut$Plain$Params } from '../fn/notification/api-notification-read-all-notification-put-plain';
import { apiNotificationReadNotificationPut } from '../fn/notification/api-notification-read-notification-put';
import { ApiNotificationReadNotificationPut$Params } from '../fn/notification/api-notification-read-notification-put';
import { apiNotificationReadNotificationPut$Plain } from '../fn/notification/api-notification-read-notification-put-plain';
import { ApiNotificationReadNotificationPut$Plain$Params } from '../fn/notification/api-notification-read-notification-put-plain';
import { apiNotificationUnReadNotificationCountGet } from '../fn/notification/api-notification-un-read-notification-count-get';
import { ApiNotificationUnReadNotificationCountGet$Params } from '../fn/notification/api-notification-un-read-notification-count-get';
import { apiNotificationUnReadNotificationCountGet$Plain } from '../fn/notification/api-notification-un-read-notification-count-get-plain';
import { ApiNotificationUnReadNotificationCountGet$Plain$Params } from '../fn/notification/api-notification-un-read-notification-count-get-plain';
import { NotificationDtoListPagination } from '../models/notification-dto-list-pagination';
import { NotificationDtoPagination } from '../models/notification-dto-pagination';

@Injectable({ providedIn: 'root' })
export class NotificationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiNotificationGetAdminNotifcationsGet()` */
  static readonly ApiNotificationGetAdminNotifcationsGetPath = '/api/Notification/GetAdminNotifcations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNotificationGetAdminNotifcationsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationGetAdminNotifcationsGet$Plain$Response(params?: ApiNotificationGetAdminNotifcationsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NotificationDtoListPagination>> {
    return apiNotificationGetAdminNotifcationsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiNotificationGetAdminNotifcationsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationGetAdminNotifcationsGet$Plain(params?: ApiNotificationGetAdminNotifcationsGet$Plain$Params, context?: HttpContext): Observable<NotificationDtoListPagination> {
    return this.apiNotificationGetAdminNotifcationsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NotificationDtoListPagination>): NotificationDtoListPagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNotificationGetAdminNotifcationsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationGetAdminNotifcationsGet$Response(params?: ApiNotificationGetAdminNotifcationsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<NotificationDtoListPagination>> {
    return apiNotificationGetAdminNotifcationsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiNotificationGetAdminNotifcationsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationGetAdminNotifcationsGet(params?: ApiNotificationGetAdminNotifcationsGet$Params, context?: HttpContext): Observable<NotificationDtoListPagination> {
    return this.apiNotificationGetAdminNotifcationsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<NotificationDtoListPagination>): NotificationDtoListPagination => r.body)
    );
  }

  /** Path part for operation `apiNotificationGetStudentNotifcationsGet()` */
  static readonly ApiNotificationGetStudentNotifcationsGetPath = '/api/Notification/GetStudentNotifcations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNotificationGetStudentNotifcationsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationGetStudentNotifcationsGet$Plain$Response(params?: ApiNotificationGetStudentNotifcationsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NotificationDtoListPagination>> {
    return apiNotificationGetStudentNotifcationsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiNotificationGetStudentNotifcationsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationGetStudentNotifcationsGet$Plain(params?: ApiNotificationGetStudentNotifcationsGet$Plain$Params, context?: HttpContext): Observable<NotificationDtoListPagination> {
    return this.apiNotificationGetStudentNotifcationsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NotificationDtoListPagination>): NotificationDtoListPagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNotificationGetStudentNotifcationsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationGetStudentNotifcationsGet$Response(params?: ApiNotificationGetStudentNotifcationsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<NotificationDtoListPagination>> {
    return apiNotificationGetStudentNotifcationsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiNotificationGetStudentNotifcationsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationGetStudentNotifcationsGet(params?: ApiNotificationGetStudentNotifcationsGet$Params, context?: HttpContext): Observable<NotificationDtoListPagination> {
    return this.apiNotificationGetStudentNotifcationsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<NotificationDtoListPagination>): NotificationDtoListPagination => r.body)
    );
  }

  /** Path part for operation `apiNotificationUnReadNotificationCountGet()` */
  static readonly ApiNotificationUnReadNotificationCountGetPath = '/api/Notification/UnReadNotificationCount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNotificationUnReadNotificationCountGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationUnReadNotificationCountGet$Plain$Response(params?: ApiNotificationUnReadNotificationCountGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return apiNotificationUnReadNotificationCountGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiNotificationUnReadNotificationCountGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationUnReadNotificationCountGet$Plain(params?: ApiNotificationUnReadNotificationCountGet$Plain$Params, context?: HttpContext): Observable<number> {
    return this.apiNotificationUnReadNotificationCountGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNotificationUnReadNotificationCountGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationUnReadNotificationCountGet$Response(params?: ApiNotificationUnReadNotificationCountGet$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return apiNotificationUnReadNotificationCountGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiNotificationUnReadNotificationCountGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationUnReadNotificationCountGet(params?: ApiNotificationUnReadNotificationCountGet$Params, context?: HttpContext): Observable<number> {
    return this.apiNotificationUnReadNotificationCountGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `apiNotificationReadAllNotificationPut()` */
  static readonly ApiNotificationReadAllNotificationPutPath = '/api/Notification/ReadAllNotification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNotificationReadAllNotificationPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationReadAllNotificationPut$Plain$Response(params?: ApiNotificationReadAllNotificationPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NotificationDtoPagination>> {
    return apiNotificationReadAllNotificationPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiNotificationReadAllNotificationPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationReadAllNotificationPut$Plain(params?: ApiNotificationReadAllNotificationPut$Plain$Params, context?: HttpContext): Observable<NotificationDtoPagination> {
    return this.apiNotificationReadAllNotificationPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NotificationDtoPagination>): NotificationDtoPagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNotificationReadAllNotificationPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationReadAllNotificationPut$Response(params?: ApiNotificationReadAllNotificationPut$Params, context?: HttpContext): Observable<StrictHttpResponse<NotificationDtoPagination>> {
    return apiNotificationReadAllNotificationPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiNotificationReadAllNotificationPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationReadAllNotificationPut(params?: ApiNotificationReadAllNotificationPut$Params, context?: HttpContext): Observable<NotificationDtoPagination> {
    return this.apiNotificationReadAllNotificationPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<NotificationDtoPagination>): NotificationDtoPagination => r.body)
    );
  }

  /** Path part for operation `apiNotificationReadNotificationPut()` */
  static readonly ApiNotificationReadNotificationPutPath = '/api/Notification/ReadNotification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNotificationReadNotificationPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationReadNotificationPut$Plain$Response(params?: ApiNotificationReadNotificationPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NotificationDtoPagination>> {
    return apiNotificationReadNotificationPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiNotificationReadNotificationPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationReadNotificationPut$Plain(params?: ApiNotificationReadNotificationPut$Plain$Params, context?: HttpContext): Observable<NotificationDtoPagination> {
    return this.apiNotificationReadNotificationPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NotificationDtoPagination>): NotificationDtoPagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNotificationReadNotificationPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationReadNotificationPut$Response(params?: ApiNotificationReadNotificationPut$Params, context?: HttpContext): Observable<StrictHttpResponse<NotificationDtoPagination>> {
    return apiNotificationReadNotificationPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiNotificationReadNotificationPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationReadNotificationPut(params?: ApiNotificationReadNotificationPut$Params, context?: HttpContext): Observable<NotificationDtoPagination> {
    return this.apiNotificationReadNotificationPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<NotificationDtoPagination>): NotificationDtoPagination => r.body)
    );
  }

}
