/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiRateTeacherGet } from '../fn/rate-teacher/api-rate-teacher-get';
import { ApiRateTeacherGet$Params } from '../fn/rate-teacher/api-rate-teacher-get';
import { apiRateTeacherGet$Plain } from '../fn/rate-teacher/api-rate-teacher-get-plain';
import { ApiRateTeacherGet$Plain$Params } from '../fn/rate-teacher/api-rate-teacher-get-plain';
import { apiRateTeacherIdDelete } from '../fn/rate-teacher/api-rate-teacher-id-delete';
import { ApiRateTeacherIdDelete$Params } from '../fn/rate-teacher/api-rate-teacher-id-delete';
import { apiRateTeacherIdGet } from '../fn/rate-teacher/api-rate-teacher-id-get';
import { ApiRateTeacherIdGet$Params } from '../fn/rate-teacher/api-rate-teacher-id-get';
import { apiRateTeacherIdGet$Plain } from '../fn/rate-teacher/api-rate-teacher-id-get-plain';
import { ApiRateTeacherIdGet$Plain$Params } from '../fn/rate-teacher/api-rate-teacher-id-get-plain';
import { apiRateTeacherPost } from '../fn/rate-teacher/api-rate-teacher-post';
import { ApiRateTeacherPost$Params } from '../fn/rate-teacher/api-rate-teacher-post';
import { ViewRateTeacherDto } from '../models/view-rate-teacher-dto';
import { ViewRateTeacherDtoIEnumerablePagination } from '../models/view-rate-teacher-dto-i-enumerable-pagination';

@Injectable({ providedIn: 'root' })
export class RateTeacherService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiRateTeacherGet()` */
  static readonly ApiRateTeacherGetPath = '/api/RateTeacher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRateTeacherGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateTeacherGet$Plain$Response(params?: ApiRateTeacherGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewRateTeacherDtoIEnumerablePagination>> {
    return apiRateTeacherGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRateTeacherGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateTeacherGet$Plain(params?: ApiRateTeacherGet$Plain$Params, context?: HttpContext): Observable<ViewRateTeacherDtoIEnumerablePagination> {
    return this.apiRateTeacherGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewRateTeacherDtoIEnumerablePagination>): ViewRateTeacherDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRateTeacherGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateTeacherGet$Response(params?: ApiRateTeacherGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewRateTeacherDtoIEnumerablePagination>> {
    return apiRateTeacherGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRateTeacherGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateTeacherGet(params?: ApiRateTeacherGet$Params, context?: HttpContext): Observable<ViewRateTeacherDtoIEnumerablePagination> {
    return this.apiRateTeacherGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewRateTeacherDtoIEnumerablePagination>): ViewRateTeacherDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiRateTeacherPost()` */
  static readonly ApiRateTeacherPostPath = '/api/RateTeacher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRateTeacherPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiRateTeacherPost$Response(params?: ApiRateTeacherPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiRateTeacherPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRateTeacherPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiRateTeacherPost(params?: ApiRateTeacherPost$Params, context?: HttpContext): Observable<void> {
    return this.apiRateTeacherPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiRateTeacherIdGet()` */
  static readonly ApiRateTeacherIdGetPath = '/api/RateTeacher/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRateTeacherIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateTeacherIdGet$Plain$Response(params: ApiRateTeacherIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewRateTeacherDto>> {
    return apiRateTeacherIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRateTeacherIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateTeacherIdGet$Plain(params: ApiRateTeacherIdGet$Plain$Params, context?: HttpContext): Observable<ViewRateTeacherDto> {
    return this.apiRateTeacherIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewRateTeacherDto>): ViewRateTeacherDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRateTeacherIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateTeacherIdGet$Response(params: ApiRateTeacherIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewRateTeacherDto>> {
    return apiRateTeacherIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRateTeacherIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateTeacherIdGet(params: ApiRateTeacherIdGet$Params, context?: HttpContext): Observable<ViewRateTeacherDto> {
    return this.apiRateTeacherIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewRateTeacherDto>): ViewRateTeacherDto => r.body)
    );
  }

  /** Path part for operation `apiRateTeacherIdDelete()` */
  static readonly ApiRateTeacherIdDeletePath = '/api/RateTeacher/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRateTeacherIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateTeacherIdDelete$Response(params: ApiRateTeacherIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiRateTeacherIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRateTeacherIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateTeacherIdDelete(params: ApiRateTeacherIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiRateTeacherIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
