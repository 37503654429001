/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AddStudentSubjectTeachersDto } from '../../models/add-student-subject-teachers-dto';
import { StudentType } from '../../models/student-type';

export interface ApiStudentPut$Params {
      body?: {
'Id'?: string;
'Name'?: string;
'Status'?: string;
'PhoneNumber'?: string;
'Img'?: Blob;
'ParentPhone'?: string;
'ParentPhone2'?: string;
'Type'?: StudentType;
'joinDate'?: string;
'ApplicationUserId'?: string;
'LevelYearsId'?: number;
'StudentSubjectTeachers'?: Array<AddStudentSubjectTeachersDto>;
}
}

export function apiStudentPut(http: HttpClient, rootUrl: string, params?: ApiStudentPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, apiStudentPut.PATH, 'put');
  if (params) {
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

apiStudentPut.PATH = '/api/Student';
