/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiRateVideoGet } from '../fn/rate-video/api-rate-video-get';
import { ApiRateVideoGet$Params } from '../fn/rate-video/api-rate-video-get';
import { apiRateVideoGet$Plain } from '../fn/rate-video/api-rate-video-get-plain';
import { ApiRateVideoGet$Plain$Params } from '../fn/rate-video/api-rate-video-get-plain';
import { apiRateVideoIdDelete } from '../fn/rate-video/api-rate-video-id-delete';
import { ApiRateVideoIdDelete$Params } from '../fn/rate-video/api-rate-video-id-delete';
import { apiRateVideoIdGet } from '../fn/rate-video/api-rate-video-id-get';
import { ApiRateVideoIdGet$Params } from '../fn/rate-video/api-rate-video-id-get';
import { apiRateVideoIdGet$Plain } from '../fn/rate-video/api-rate-video-id-get-plain';
import { ApiRateVideoIdGet$Plain$Params } from '../fn/rate-video/api-rate-video-id-get-plain';
import { apiRateVideoPost } from '../fn/rate-video/api-rate-video-post';
import { ApiRateVideoPost$Params } from '../fn/rate-video/api-rate-video-post';
import { ViewRateVideoDto } from '../models/view-rate-video-dto';
import { ViewRateVideoDtoIEnumerablePagination } from '../models/view-rate-video-dto-i-enumerable-pagination';

@Injectable({ providedIn: 'root' })
export class RateVideoService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiRateVideoGet()` */
  static readonly ApiRateVideoGetPath = '/api/RateVideo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRateVideoGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateVideoGet$Plain$Response(params?: ApiRateVideoGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewRateVideoDtoIEnumerablePagination>> {
    return apiRateVideoGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRateVideoGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateVideoGet$Plain(params?: ApiRateVideoGet$Plain$Params, context?: HttpContext): Observable<ViewRateVideoDtoIEnumerablePagination> {
    return this.apiRateVideoGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewRateVideoDtoIEnumerablePagination>): ViewRateVideoDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRateVideoGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateVideoGet$Response(params?: ApiRateVideoGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewRateVideoDtoIEnumerablePagination>> {
    return apiRateVideoGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRateVideoGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateVideoGet(params?: ApiRateVideoGet$Params, context?: HttpContext): Observable<ViewRateVideoDtoIEnumerablePagination> {
    return this.apiRateVideoGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewRateVideoDtoIEnumerablePagination>): ViewRateVideoDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiRateVideoPost()` */
  static readonly ApiRateVideoPostPath = '/api/RateVideo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRateVideoPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiRateVideoPost$Response(params?: ApiRateVideoPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiRateVideoPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRateVideoPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiRateVideoPost(params?: ApiRateVideoPost$Params, context?: HttpContext): Observable<void> {
    return this.apiRateVideoPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiRateVideoIdGet()` */
  static readonly ApiRateVideoIdGetPath = '/api/RateVideo/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRateVideoIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateVideoIdGet$Plain$Response(params: ApiRateVideoIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewRateVideoDto>> {
    return apiRateVideoIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRateVideoIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateVideoIdGet$Plain(params: ApiRateVideoIdGet$Plain$Params, context?: HttpContext): Observable<ViewRateVideoDto> {
    return this.apiRateVideoIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewRateVideoDto>): ViewRateVideoDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRateVideoIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateVideoIdGet$Response(params: ApiRateVideoIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewRateVideoDto>> {
    return apiRateVideoIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRateVideoIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateVideoIdGet(params: ApiRateVideoIdGet$Params, context?: HttpContext): Observable<ViewRateVideoDto> {
    return this.apiRateVideoIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewRateVideoDto>): ViewRateVideoDto => r.body)
    );
  }

  /** Path part for operation `apiRateVideoIdDelete()` */
  static readonly ApiRateVideoIdDeletePath = '/api/RateVideo/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRateVideoIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateVideoIdDelete$Response(params: ApiRateVideoIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiRateVideoIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRateVideoIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRateVideoIdDelete(params: ApiRateVideoIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiRateVideoIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
