/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AddEditChoiceDto } from '../../models/add-edit-choice-dto';
import { QuestionType } from '../../models/question-type';

export interface ApiQuestionPost$Params {
      body?: {
'Id'?: number;
'Body'?: string;
'Type'?: QuestionType;
'Img'?: Blob;
'Degree'?: number;
'ArticleRightAnswer'?: string;
'ExamId'?: number;
'Sort'?: number;
'Choises'?: Array<AddEditChoiceDto>;
}
}

export function apiQuestionPost(http: HttpClient, rootUrl: string, params?: ApiQuestionPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, apiQuestionPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

apiQuestionPost.PATH = '/api/Question';
