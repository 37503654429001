/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiAccountAddUserPost } from '../fn/account/api-account-add-user-post';
import { ApiAccountAddUserPost$Params } from '../fn/account/api-account-add-user-post';
import { apiAccountAddUserPost$Plain } from '../fn/account/api-account-add-user-post-plain';
import { ApiAccountAddUserPost$Plain$Params } from '../fn/account/api-account-add-user-post-plain';
import { apiAccountChangePasswordPost } from '../fn/account/api-account-change-password-post';
import { ApiAccountChangePasswordPost$Params } from '../fn/account/api-account-change-password-post';
import { apiAccountChangePasswordPost$Plain } from '../fn/account/api-account-change-password-post-plain';
import { ApiAccountChangePasswordPost$Plain$Params } from '../fn/account/api-account-change-password-post-plain';
import { apiAccountDeleteUsersDelete } from '../fn/account/api-account-delete-users-delete';
import { ApiAccountDeleteUsersDelete$Params } from '../fn/account/api-account-delete-users-delete';
import { apiAccountDeleteUsersDelete$Plain } from '../fn/account/api-account-delete-users-delete-plain';
import { ApiAccountDeleteUsersDelete$Plain$Params } from '../fn/account/api-account-delete-users-delete-plain';
import { apiAccountGet } from '../fn/account/api-account-get';
import { ApiAccountGet$Params } from '../fn/account/api-account-get';
import { apiAccountGet$Plain } from '../fn/account/api-account-get-plain';
import { ApiAccountGet$Plain$Params } from '../fn/account/api-account-get-plain';
import { apiAccountGetPermissionsPost } from '../fn/account/api-account-get-permissions-post';
import { ApiAccountGetPermissionsPost$Params } from '../fn/account/api-account-get-permissions-post';
import { apiAccountGetPermissionsPost$Plain } from '../fn/account/api-account-get-permissions-post-plain';
import { ApiAccountGetPermissionsPost$Plain$Params } from '../fn/account/api-account-get-permissions-post-plain';
import { apiAccountGetUserByIdGet } from '../fn/account/api-account-get-user-by-id-get';
import { ApiAccountGetUserByIdGet$Params } from '../fn/account/api-account-get-user-by-id-get';
import { apiAccountGetUserByIdGet$Plain } from '../fn/account/api-account-get-user-by-id-get-plain';
import { ApiAccountGetUserByIdGet$Plain$Params } from '../fn/account/api-account-get-user-by-id-get-plain';
import { apiAccountGetUsersGet } from '../fn/account/api-account-get-users-get';
import { ApiAccountGetUsersGet$Params } from '../fn/account/api-account-get-users-get';
import { apiAccountGetUsersGet$Plain } from '../fn/account/api-account-get-users-get-plain';
import { ApiAccountGetUsersGet$Plain$Params } from '../fn/account/api-account-get-users-get-plain';
import { apiAccountLoginPost } from '../fn/account/api-account-login-post';
import { ApiAccountLoginPost$Params } from '../fn/account/api-account-login-post';
import { apiAccountLoginPost$Plain } from '../fn/account/api-account-login-post-plain';
import { ApiAccountLoginPost$Plain$Params } from '../fn/account/api-account-login-post-plain';
import { apiAccountRegisterParentPost } from '../fn/account/api-account-register-parent-post';
import { ApiAccountRegisterParentPost$Params } from '../fn/account/api-account-register-parent-post';
import { apiAccountRegisterParentPost$Plain } from '../fn/account/api-account-register-parent-post-plain';
import { ApiAccountRegisterParentPost$Plain$Params } from '../fn/account/api-account-register-parent-post-plain';
import { apiAccountRegisterStudentPost } from '../fn/account/api-account-register-student-post';
import { ApiAccountRegisterStudentPost$Params } from '../fn/account/api-account-register-student-post';
import { apiAccountRegisterStudentPost$Plain } from '../fn/account/api-account-register-student-post-plain';
import { ApiAccountRegisterStudentPost$Plain$Params } from '../fn/account/api-account-register-student-post-plain';
import { apiAccountUpdatePasswordPost } from '../fn/account/api-account-update-password-post';
import { ApiAccountUpdatePasswordPost$Params } from '../fn/account/api-account-update-password-post';
import { apiAccountUpdatePasswordPost$Plain } from '../fn/account/api-account-update-password-post-plain';
import { ApiAccountUpdatePasswordPost$Plain$Params } from '../fn/account/api-account-update-password-post-plain';
import { ApplicationUser } from '../models/application-user';
import { LoginResponseDto } from '../models/login-response-dto';
import { UserDto } from '../models/user-dto';
import { UserDtoIEnumerablePagination } from '../models/user-dto-i-enumerable-pagination';

@Injectable({ providedIn: 'root' })
export class AccountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiAccountLoginPost()` */
  static readonly ApiAccountLoginPostPath = '/api/Account/Login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountLoginPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountLoginPost$Plain$Response(params?: ApiAccountLoginPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginResponseDto>> {
    return apiAccountLoginPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountLoginPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountLoginPost$Plain(params?: ApiAccountLoginPost$Plain$Params, context?: HttpContext): Observable<LoginResponseDto> {
    return this.apiAccountLoginPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponseDto>): LoginResponseDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountLoginPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountLoginPost$Response(params?: ApiAccountLoginPost$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginResponseDto>> {
    return apiAccountLoginPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountLoginPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountLoginPost(params?: ApiAccountLoginPost$Params, context?: HttpContext): Observable<LoginResponseDto> {
    return this.apiAccountLoginPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponseDto>): LoginResponseDto => r.body)
    );
  }

  /** Path part for operation `apiAccountAddUserPost()` */
  static readonly ApiAccountAddUserPostPath = '/api/Account/AddUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountAddUserPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountAddUserPost$Plain$Response(params?: ApiAccountAddUserPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ApplicationUser>> {
    return apiAccountAddUserPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountAddUserPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountAddUserPost$Plain(params?: ApiAccountAddUserPost$Plain$Params, context?: HttpContext): Observable<ApplicationUser> {
    return this.apiAccountAddUserPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationUser>): ApplicationUser => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountAddUserPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountAddUserPost$Response(params?: ApiAccountAddUserPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ApplicationUser>> {
    return apiAccountAddUserPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountAddUserPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountAddUserPost(params?: ApiAccountAddUserPost$Params, context?: HttpContext): Observable<ApplicationUser> {
    return this.apiAccountAddUserPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationUser>): ApplicationUser => r.body)
    );
  }

  /** Path part for operation `apiAccountGetPermissionsPost()` */
  static readonly ApiAccountGetPermissionsPostPath = '/api/Account/GetPermissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountGetPermissionsPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetPermissionsPost$Plain$Response(params?: ApiAccountGetPermissionsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return apiAccountGetPermissionsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountGetPermissionsPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetPermissionsPost$Plain(params?: ApiAccountGetPermissionsPost$Plain$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiAccountGetPermissionsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountGetPermissionsPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetPermissionsPost$Response(params?: ApiAccountGetPermissionsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return apiAccountGetPermissionsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountGetPermissionsPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetPermissionsPost(params?: ApiAccountGetPermissionsPost$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiAccountGetPermissionsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiAccountGetUsersGet()` */
  static readonly ApiAccountGetUsersGetPath = '/api/Account/GetUsers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountGetUsersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetUsersGet$Plain$Response(params?: ApiAccountGetUsersGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDtoIEnumerablePagination>> {
    return apiAccountGetUsersGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountGetUsersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetUsersGet$Plain(params?: ApiAccountGetUsersGet$Plain$Params, context?: HttpContext): Observable<UserDtoIEnumerablePagination> {
    return this.apiAccountGetUsersGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDtoIEnumerablePagination>): UserDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountGetUsersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetUsersGet$Response(params?: ApiAccountGetUsersGet$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDtoIEnumerablePagination>> {
    return apiAccountGetUsersGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountGetUsersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetUsersGet(params?: ApiAccountGetUsersGet$Params, context?: HttpContext): Observable<UserDtoIEnumerablePagination> {
    return this.apiAccountGetUsersGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDtoIEnumerablePagination>): UserDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiAccountGetUserByIdGet()` */
  static readonly ApiAccountGetUserByIdGetPath = '/api/Account/GetUserById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountGetUserByIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetUserByIdGet$Plain$Response(params?: ApiAccountGetUserByIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return apiAccountGetUserByIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountGetUserByIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetUserByIdGet$Plain(params?: ApiAccountGetUserByIdGet$Plain$Params, context?: HttpContext): Observable<UserDto> {
    return this.apiAccountGetUserByIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountGetUserByIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetUserByIdGet$Response(params?: ApiAccountGetUserByIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return apiAccountGetUserByIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountGetUserByIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetUserByIdGet(params?: ApiAccountGetUserByIdGet$Params, context?: HttpContext): Observable<UserDto> {
    return this.apiAccountGetUserByIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `apiAccountDeleteUsersDelete()` */
  static readonly ApiAccountDeleteUsersDeletePath = '/api/Account/DeleteUsers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountDeleteUsersDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountDeleteUsersDelete$Plain$Response(params?: ApiAccountDeleteUsersDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return apiAccountDeleteUsersDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountDeleteUsersDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountDeleteUsersDelete$Plain(params?: ApiAccountDeleteUsersDelete$Plain$Params, context?: HttpContext): Observable<UserDto> {
    return this.apiAccountDeleteUsersDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountDeleteUsersDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountDeleteUsersDelete$Response(params?: ApiAccountDeleteUsersDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return apiAccountDeleteUsersDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountDeleteUsersDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountDeleteUsersDelete(params?: ApiAccountDeleteUsersDelete$Params, context?: HttpContext): Observable<UserDto> {
    return this.apiAccountDeleteUsersDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `apiAccountGet()` */
  static readonly ApiAccountGetPath = '/api/Account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGet$Plain$Response(params?: ApiAccountGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return apiAccountGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGet$Plain(params?: ApiAccountGet$Plain$Params, context?: HttpContext): Observable<UserDto> {
    return this.apiAccountGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGet$Response(params?: ApiAccountGet$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return apiAccountGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGet(params?: ApiAccountGet$Params, context?: HttpContext): Observable<UserDto> {
    return this.apiAccountGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `apiAccountRegisterParentPost()` */
  static readonly ApiAccountRegisterParentPostPath = '/api/Account/RegisterParent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountRegisterParentPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAccountRegisterParentPost$Plain$Response(params?: ApiAccountRegisterParentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ApplicationUser>> {
    return apiAccountRegisterParentPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountRegisterParentPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAccountRegisterParentPost$Plain(params?: ApiAccountRegisterParentPost$Plain$Params, context?: HttpContext): Observable<ApplicationUser> {
    return this.apiAccountRegisterParentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationUser>): ApplicationUser => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountRegisterParentPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAccountRegisterParentPost$Response(params?: ApiAccountRegisterParentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ApplicationUser>> {
    return apiAccountRegisterParentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountRegisterParentPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAccountRegisterParentPost(params?: ApiAccountRegisterParentPost$Params, context?: HttpContext): Observable<ApplicationUser> {
    return this.apiAccountRegisterParentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationUser>): ApplicationUser => r.body)
    );
  }

  /** Path part for operation `apiAccountRegisterStudentPost()` */
  static readonly ApiAccountRegisterStudentPostPath = '/api/Account/RegisterStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountRegisterStudentPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAccountRegisterStudentPost$Plain$Response(params?: ApiAccountRegisterStudentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ApplicationUser>> {
    return apiAccountRegisterStudentPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountRegisterStudentPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAccountRegisterStudentPost$Plain(params?: ApiAccountRegisterStudentPost$Plain$Params, context?: HttpContext): Observable<ApplicationUser> {
    return this.apiAccountRegisterStudentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationUser>): ApplicationUser => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountRegisterStudentPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAccountRegisterStudentPost$Response(params?: ApiAccountRegisterStudentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ApplicationUser>> {
    return apiAccountRegisterStudentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountRegisterStudentPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAccountRegisterStudentPost(params?: ApiAccountRegisterStudentPost$Params, context?: HttpContext): Observable<ApplicationUser> {
    return this.apiAccountRegisterStudentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationUser>): ApplicationUser => r.body)
    );
  }

  /** Path part for operation `apiAccountChangePasswordPost()` */
  static readonly ApiAccountChangePasswordPostPath = '/api/Account/ChangePassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountChangePasswordPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountChangePasswordPost$Plain$Response(params?: ApiAccountChangePasswordPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return apiAccountChangePasswordPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountChangePasswordPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountChangePasswordPost$Plain(params?: ApiAccountChangePasswordPost$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.apiAccountChangePasswordPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountChangePasswordPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountChangePasswordPost$Response(params?: ApiAccountChangePasswordPost$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return apiAccountChangePasswordPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountChangePasswordPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountChangePasswordPost(params?: ApiAccountChangePasswordPost$Params, context?: HttpContext): Observable<boolean> {
    return this.apiAccountChangePasswordPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiAccountUpdatePasswordPost()` */
  static readonly ApiAccountUpdatePasswordPostPath = '/api/Account/UpdatePassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountUpdatePasswordPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountUpdatePasswordPost$Plain$Response(params?: ApiAccountUpdatePasswordPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return apiAccountUpdatePasswordPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountUpdatePasswordPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountUpdatePasswordPost$Plain(params?: ApiAccountUpdatePasswordPost$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.apiAccountUpdatePasswordPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountUpdatePasswordPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountUpdatePasswordPost$Response(params?: ApiAccountUpdatePasswordPost$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return apiAccountUpdatePasswordPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountUpdatePasswordPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountUpdatePasswordPost(params?: ApiAccountUpdatePasswordPost$Params, context?: HttpContext): Observable<boolean> {
    return this.apiAccountUpdatePasswordPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
