/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiStudentAddStudentPost } from '../fn/student/api-student-add-student-post';
import { ApiStudentAddStudentPost$Params } from '../fn/student/api-student-add-student-post';
import { apiStudentAddStudentSubjectTeacherPost } from '../fn/student/api-student-add-student-subject-teacher-post';
import { ApiStudentAddStudentSubjectTeacherPost$Params } from '../fn/student/api-student-add-student-subject-teacher-post';
import { apiStudentAddStudentSubjectTeacherPost$Plain } from '../fn/student/api-student-add-student-subject-teacher-post-plain';
import { ApiStudentAddStudentSubjectTeacherPost$Plain$Params } from '../fn/student/api-student-add-student-subject-teacher-post-plain';
import { apiStudentChangeStatusPut } from '../fn/student/api-student-change-status-put';
import { ApiStudentChangeStatusPut$Params } from '../fn/student/api-student-change-status-put';
import { apiStudentChangeStudentSubjectTeacherStatusPut } from '../fn/student/api-student-change-student-subject-teacher-status-put';
import { ApiStudentChangeStudentSubjectTeacherStatusPut$Params } from '../fn/student/api-student-change-student-subject-teacher-status-put';
import { apiStudentGet } from '../fn/student/api-student-get';
import { ApiStudentGet$Params } from '../fn/student/api-student-get';
import { apiStudentGet$Plain } from '../fn/student/api-student-get-plain';
import { ApiStudentGet$Plain$Params } from '../fn/student/api-student-get-plain';
import { apiStudentGetAcceptedStudentsGet } from '../fn/student/api-student-get-accepted-students-get';
import { ApiStudentGetAcceptedStudentsGet$Params } from '../fn/student/api-student-get-accepted-students-get';
import { apiStudentGetAcceptedStudentsGet$Plain } from '../fn/student/api-student-get-accepted-students-get-plain';
import { ApiStudentGetAcceptedStudentsGet$Plain$Params } from '../fn/student/api-student-get-accepted-students-get-plain';
import { apiStudentGetCoursesChapterGet } from '../fn/student/api-student-get-courses-chapter-get';
import { ApiStudentGetCoursesChapterGet$Params } from '../fn/student/api-student-get-courses-chapter-get';
import { apiStudentGetCoursesChapterGet$Plain } from '../fn/student/api-student-get-courses-chapter-get-plain';
import { ApiStudentGetCoursesChapterGet$Plain$Params } from '../fn/student/api-student-get-courses-chapter-get-plain';
import { apiStudentGetCoursesGet } from '../fn/student/api-student-get-courses-get';
import { ApiStudentGetCoursesGet$Params } from '../fn/student/api-student-get-courses-get';
import { apiStudentGetCoursesGet$Plain } from '../fn/student/api-student-get-courses-get-plain';
import { ApiStudentGetCoursesGet$Plain$Params } from '../fn/student/api-student-get-courses-get-plain';
import { apiStudentGetParentRequestsForStudentGet } from '../fn/student/api-student-get-parent-requests-for-student-get';
import { ApiStudentGetParentRequestsForStudentGet$Params } from '../fn/student/api-student-get-parent-requests-for-student-get';
import { apiStudentGetParentRequestsForStudentGet$Plain } from '../fn/student/api-student-get-parent-requests-for-student-get-plain';
import { ApiStudentGetParentRequestsForStudentGet$Plain$Params } from '../fn/student/api-student-get-parent-requests-for-student-get-plain';
import { apiStudentGetPindedStudentsGet } from '../fn/student/api-student-get-pinded-students-get';
import { ApiStudentGetPindedStudentsGet$Params } from '../fn/student/api-student-get-pinded-students-get';
import { apiStudentGetPindedStudentsGet$Plain } from '../fn/student/api-student-get-pinded-students-get-plain';
import { ApiStudentGetPindedStudentsGet$Plain$Params } from '../fn/student/api-student-get-pinded-students-get-plain';
import { apiStudentGetStudentSubjectTeacherExelReportGet } from '../fn/student/api-student-get-student-subject-teacher-exel-report-get';
import { ApiStudentGetStudentSubjectTeacherExelReportGet$Params } from '../fn/student/api-student-get-student-subject-teacher-exel-report-get';
import { apiStudentGetStudentSubjectTeacherExelReportGet$Plain } from '../fn/student/api-student-get-student-subject-teacher-exel-report-get-plain';
import { ApiStudentGetStudentSubjectTeacherExelReportGet$Plain$Params } from '../fn/student/api-student-get-student-subject-teacher-exel-report-get-plain';
import { apiStudentGetStudentSubjectTeacherForStudentGet } from '../fn/student/api-student-get-student-subject-teacher-for-student-get';
import { ApiStudentGetStudentSubjectTeacherForStudentGet$Params } from '../fn/student/api-student-get-student-subject-teacher-for-student-get';
import { apiStudentGetStudentSubjectTeacherForStudentGet$Plain } from '../fn/student/api-student-get-student-subject-teacher-for-student-get-plain';
import { ApiStudentGetStudentSubjectTeacherForStudentGet$Plain$Params } from '../fn/student/api-student-get-student-subject-teacher-for-student-get-plain';
import { apiStudentGetStudentSubjectTeacherGet } from '../fn/student/api-student-get-student-subject-teacher-get';
import { ApiStudentGetStudentSubjectTeacherGet$Params } from '../fn/student/api-student-get-student-subject-teacher-get';
import { apiStudentGetStudentSubjectTeacherGet$Plain } from '../fn/student/api-student-get-student-subject-teacher-get-plain';
import { ApiStudentGetStudentSubjectTeacherGet$Plain$Params } from '../fn/student/api-student-get-student-subject-teacher-get-plain';
import { apiStudentIdDelete } from '../fn/student/api-student-id-delete';
import { ApiStudentIdDelete$Params } from '../fn/student/api-student-id-delete';
import { apiStudentIdGet } from '../fn/student/api-student-id-get';
import { ApiStudentIdGet$Params } from '../fn/student/api-student-id-get';
import { apiStudentIdGet$Plain } from '../fn/student/api-student-id-get-plain';
import { ApiStudentIdGet$Plain$Params } from '../fn/student/api-student-id-get-plain';
import { apiStudentPut } from '../fn/student/api-student-put';
import { ApiStudentPut$Params } from '../fn/student/api-student-put';
import { apiStudentRequestCoursePost } from '../fn/student/api-student-request-course-post';
import { ApiStudentRequestCoursePost$Params } from '../fn/student/api-student-request-course-post';
import { apiStudentRequestCoursePost$Plain } from '../fn/student/api-student-request-course-post-plain';
import { ApiStudentRequestCoursePost$Plain$Params } from '../fn/student/api-student-request-course-post-plain';
import { apiStudentUnsubscribeCourseDelete } from '../fn/student/api-student-unsubscribe-course-delete';
import { ApiStudentUnsubscribeCourseDelete$Params } from '../fn/student/api-student-unsubscribe-course-delete';
import { apiStudentWhatchVideoPost } from '../fn/student/api-student-whatch-video-post';
import { ApiStudentWhatchVideoPost$Params } from '../fn/student/api-student-whatch-video-post';
import { GetStudentSubjectTeacherDto } from '../models/get-student-subject-teacher-dto';
import { GetStudentSubjectTeacherDtoIEnumerablePagination } from '../models/get-student-subject-teacher-dto-i-enumerable-pagination';
import { UserDto } from '../models/user-dto';
import { ViewChapterDtoIEnumerablePagination } from '../models/view-chapter-dto-i-enumerable-pagination';
import { ViewStudentCourseDtoIEnumerablePagination } from '../models/view-student-course-dto-i-enumerable-pagination';
import { ViewStudentDto } from '../models/view-student-dto';
import { ViewStudentDtoIEnumerablePagination } from '../models/view-student-dto-i-enumerable-pagination';

@Injectable({ providedIn: 'root' })
export class StudentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiStudentGetPindedStudentsGet()` */
  static readonly ApiStudentGetPindedStudentsGetPath = '/api/Student/getPindedStudents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetPindedStudentsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetPindedStudentsGet$Plain$Response(params?: ApiStudentGetPindedStudentsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentDtoIEnumerablePagination>> {
    return apiStudentGetPindedStudentsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetPindedStudentsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetPindedStudentsGet$Plain(params?: ApiStudentGetPindedStudentsGet$Plain$Params, context?: HttpContext): Observable<ViewStudentDtoIEnumerablePagination> {
    return this.apiStudentGetPindedStudentsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentDtoIEnumerablePagination>): ViewStudentDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetPindedStudentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetPindedStudentsGet$Response(params?: ApiStudentGetPindedStudentsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentDtoIEnumerablePagination>> {
    return apiStudentGetPindedStudentsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetPindedStudentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetPindedStudentsGet(params?: ApiStudentGetPindedStudentsGet$Params, context?: HttpContext): Observable<ViewStudentDtoIEnumerablePagination> {
    return this.apiStudentGetPindedStudentsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentDtoIEnumerablePagination>): ViewStudentDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiStudentGetAcceptedStudentsGet()` */
  static readonly ApiStudentGetAcceptedStudentsGetPath = '/api/Student/getAcceptedStudents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetAcceptedStudentsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetAcceptedStudentsGet$Plain$Response(params?: ApiStudentGetAcceptedStudentsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentDtoIEnumerablePagination>> {
    return apiStudentGetAcceptedStudentsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetAcceptedStudentsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetAcceptedStudentsGet$Plain(params?: ApiStudentGetAcceptedStudentsGet$Plain$Params, context?: HttpContext): Observable<ViewStudentDtoIEnumerablePagination> {
    return this.apiStudentGetAcceptedStudentsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentDtoIEnumerablePagination>): ViewStudentDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetAcceptedStudentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetAcceptedStudentsGet$Response(params?: ApiStudentGetAcceptedStudentsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentDtoIEnumerablePagination>> {
    return apiStudentGetAcceptedStudentsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetAcceptedStudentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetAcceptedStudentsGet(params?: ApiStudentGetAcceptedStudentsGet$Params, context?: HttpContext): Observable<ViewStudentDtoIEnumerablePagination> {
    return this.apiStudentGetAcceptedStudentsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentDtoIEnumerablePagination>): ViewStudentDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiStudentGetCoursesGet()` */
  static readonly ApiStudentGetCoursesGetPath = '/api/Student/GetCourses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetCoursesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetCoursesGet$Plain$Response(params?: ApiStudentGetCoursesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentCourseDtoIEnumerablePagination>> {
    return apiStudentGetCoursesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetCoursesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetCoursesGet$Plain(params?: ApiStudentGetCoursesGet$Plain$Params, context?: HttpContext): Observable<ViewStudentCourseDtoIEnumerablePagination> {
    return this.apiStudentGetCoursesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentCourseDtoIEnumerablePagination>): ViewStudentCourseDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetCoursesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetCoursesGet$Response(params?: ApiStudentGetCoursesGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentCourseDtoIEnumerablePagination>> {
    return apiStudentGetCoursesGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetCoursesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetCoursesGet(params?: ApiStudentGetCoursesGet$Params, context?: HttpContext): Observable<ViewStudentCourseDtoIEnumerablePagination> {
    return this.apiStudentGetCoursesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentCourseDtoIEnumerablePagination>): ViewStudentCourseDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiStudentGetParentRequestsForStudentGet()` */
  static readonly ApiStudentGetParentRequestsForStudentGetPath = '/api/Student/GetParentRequestsForStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetParentRequestsForStudentGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetParentRequestsForStudentGet$Plain$Response(params?: ApiStudentGetParentRequestsForStudentGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserDto>>> {
    return apiStudentGetParentRequestsForStudentGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetParentRequestsForStudentGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetParentRequestsForStudentGet$Plain(params?: ApiStudentGetParentRequestsForStudentGet$Plain$Params, context?: HttpContext): Observable<Array<UserDto>> {
    return this.apiStudentGetParentRequestsForStudentGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>): Array<UserDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetParentRequestsForStudentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetParentRequestsForStudentGet$Response(params?: ApiStudentGetParentRequestsForStudentGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserDto>>> {
    return apiStudentGetParentRequestsForStudentGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetParentRequestsForStudentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetParentRequestsForStudentGet(params?: ApiStudentGetParentRequestsForStudentGet$Params, context?: HttpContext): Observable<Array<UserDto>> {
    return this.apiStudentGetParentRequestsForStudentGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>): Array<UserDto> => r.body)
    );
  }

  /** Path part for operation `apiStudentGetCoursesChapterGet()` */
  static readonly ApiStudentGetCoursesChapterGetPath = '/api/Student/GetCoursesChapter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetCoursesChapterGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetCoursesChapterGet$Plain$Response(params?: ApiStudentGetCoursesChapterGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewChapterDtoIEnumerablePagination>> {
    return apiStudentGetCoursesChapterGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetCoursesChapterGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetCoursesChapterGet$Plain(params?: ApiStudentGetCoursesChapterGet$Plain$Params, context?: HttpContext): Observable<ViewChapterDtoIEnumerablePagination> {
    return this.apiStudentGetCoursesChapterGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewChapterDtoIEnumerablePagination>): ViewChapterDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetCoursesChapterGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetCoursesChapterGet$Response(params?: ApiStudentGetCoursesChapterGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewChapterDtoIEnumerablePagination>> {
    return apiStudentGetCoursesChapterGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetCoursesChapterGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetCoursesChapterGet(params?: ApiStudentGetCoursesChapterGet$Params, context?: HttpContext): Observable<ViewChapterDtoIEnumerablePagination> {
    return this.apiStudentGetCoursesChapterGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewChapterDtoIEnumerablePagination>): ViewChapterDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiStudentGetStudentSubjectTeacherGet()` */
  static readonly ApiStudentGetStudentSubjectTeacherGetPath = '/api/Student/GetStudentSubjectTeacher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetStudentSubjectTeacherGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetStudentSubjectTeacherGet$Plain$Response(params?: ApiStudentGetStudentSubjectTeacherGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentSubjectTeacherDtoIEnumerablePagination>> {
    return apiStudentGetStudentSubjectTeacherGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetStudentSubjectTeacherGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetStudentSubjectTeacherGet$Plain(params?: ApiStudentGetStudentSubjectTeacherGet$Plain$Params, context?: HttpContext): Observable<GetStudentSubjectTeacherDtoIEnumerablePagination> {
    return this.apiStudentGetStudentSubjectTeacherGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentSubjectTeacherDtoIEnumerablePagination>): GetStudentSubjectTeacherDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetStudentSubjectTeacherGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetStudentSubjectTeacherGet$Response(params?: ApiStudentGetStudentSubjectTeacherGet$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentSubjectTeacherDtoIEnumerablePagination>> {
    return apiStudentGetStudentSubjectTeacherGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetStudentSubjectTeacherGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetStudentSubjectTeacherGet(params?: ApiStudentGetStudentSubjectTeacherGet$Params, context?: HttpContext): Observable<GetStudentSubjectTeacherDtoIEnumerablePagination> {
    return this.apiStudentGetStudentSubjectTeacherGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentSubjectTeacherDtoIEnumerablePagination>): GetStudentSubjectTeacherDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiStudentGetStudentSubjectTeacherExelReportGet()` */
  static readonly ApiStudentGetStudentSubjectTeacherExelReportGetPath = '/api/Student/GetStudentSubjectTeacherExelReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetStudentSubjectTeacherExelReportGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetStudentSubjectTeacherExelReportGet$Plain$Response(params?: ApiStudentGetStudentSubjectTeacherExelReportGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return apiStudentGetStudentSubjectTeacherExelReportGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetStudentSubjectTeacherExelReportGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetStudentSubjectTeacherExelReportGet$Plain(params?: ApiStudentGetStudentSubjectTeacherExelReportGet$Plain$Params, context?: HttpContext): Observable<Blob> {
    return this.apiStudentGetStudentSubjectTeacherExelReportGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetStudentSubjectTeacherExelReportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetStudentSubjectTeacherExelReportGet$Response(params?: ApiStudentGetStudentSubjectTeacherExelReportGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return apiStudentGetStudentSubjectTeacherExelReportGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetStudentSubjectTeacherExelReportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetStudentSubjectTeacherExelReportGet(params?: ApiStudentGetStudentSubjectTeacherExelReportGet$Params, context?: HttpContext): Observable<Blob> {
    return this.apiStudentGetStudentSubjectTeacherExelReportGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `apiStudentGetStudentSubjectTeacherForStudentGet()` */
  static readonly ApiStudentGetStudentSubjectTeacherForStudentGetPath = '/api/Student/GetStudentSubjectTeacherForStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetStudentSubjectTeacherForStudentGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetStudentSubjectTeacherForStudentGet$Plain$Response(params?: ApiStudentGetStudentSubjectTeacherForStudentGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GetStudentSubjectTeacherDto>>> {
    return apiStudentGetStudentSubjectTeacherForStudentGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetStudentSubjectTeacherForStudentGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetStudentSubjectTeacherForStudentGet$Plain(params?: ApiStudentGetStudentSubjectTeacherForStudentGet$Plain$Params, context?: HttpContext): Observable<Array<GetStudentSubjectTeacherDto>> {
    return this.apiStudentGetStudentSubjectTeacherForStudentGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetStudentSubjectTeacherDto>>): Array<GetStudentSubjectTeacherDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetStudentSubjectTeacherForStudentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetStudentSubjectTeacherForStudentGet$Response(params?: ApiStudentGetStudentSubjectTeacherForStudentGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GetStudentSubjectTeacherDto>>> {
    return apiStudentGetStudentSubjectTeacherForStudentGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetStudentSubjectTeacherForStudentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGetStudentSubjectTeacherForStudentGet(params?: ApiStudentGetStudentSubjectTeacherForStudentGet$Params, context?: HttpContext): Observable<Array<GetStudentSubjectTeacherDto>> {
    return this.apiStudentGetStudentSubjectTeacherForStudentGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetStudentSubjectTeacherDto>>): Array<GetStudentSubjectTeacherDto> => r.body)
    );
  }

  /** Path part for operation `apiStudentAddStudentSubjectTeacherPost()` */
  static readonly ApiStudentAddStudentSubjectTeacherPostPath = '/api/Student/AddStudentSubjectTeacher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentAddStudentSubjectTeacherPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentAddStudentSubjectTeacherPost$Plain$Response(params?: ApiStudentAddStudentSubjectTeacherPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GetStudentSubjectTeacherDto>>> {
    return apiStudentAddStudentSubjectTeacherPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentAddStudentSubjectTeacherPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentAddStudentSubjectTeacherPost$Plain(params?: ApiStudentAddStudentSubjectTeacherPost$Plain$Params, context?: HttpContext): Observable<Array<GetStudentSubjectTeacherDto>> {
    return this.apiStudentAddStudentSubjectTeacherPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetStudentSubjectTeacherDto>>): Array<GetStudentSubjectTeacherDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentAddStudentSubjectTeacherPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentAddStudentSubjectTeacherPost$Response(params?: ApiStudentAddStudentSubjectTeacherPost$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GetStudentSubjectTeacherDto>>> {
    return apiStudentAddStudentSubjectTeacherPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentAddStudentSubjectTeacherPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentAddStudentSubjectTeacherPost(params?: ApiStudentAddStudentSubjectTeacherPost$Params, context?: HttpContext): Observable<Array<GetStudentSubjectTeacherDto>> {
    return this.apiStudentAddStudentSubjectTeacherPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetStudentSubjectTeacherDto>>): Array<GetStudentSubjectTeacherDto> => r.body)
    );
  }

  /** Path part for operation `apiStudentChangeStudentSubjectTeacherStatusPut()` */
  static readonly ApiStudentChangeStudentSubjectTeacherStatusPutPath = '/api/Student/ChangeStudentSubjectTeacherStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentChangeStudentSubjectTeacherStatusPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentChangeStudentSubjectTeacherStatusPut$Response(params?: ApiStudentChangeStudentSubjectTeacherStatusPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiStudentChangeStudentSubjectTeacherStatusPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentChangeStudentSubjectTeacherStatusPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentChangeStudentSubjectTeacherStatusPut(params?: ApiStudentChangeStudentSubjectTeacherStatusPut$Params, context?: HttpContext): Observable<void> {
    return this.apiStudentChangeStudentSubjectTeacherStatusPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiStudentChangeStatusPut()` */
  static readonly ApiStudentChangeStatusPutPath = '/api/Student/ChangeStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentChangeStatusPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentChangeStatusPut$Response(params?: ApiStudentChangeStatusPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiStudentChangeStatusPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentChangeStatusPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentChangeStatusPut(params?: ApiStudentChangeStatusPut$Params, context?: HttpContext): Observable<void> {
    return this.apiStudentChangeStatusPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiStudentUnsubscribeCourseDelete()` */
  static readonly ApiStudentUnsubscribeCourseDeletePath = '/api/Student/UnsubscribeCourse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentUnsubscribeCourseDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentUnsubscribeCourseDelete$Response(params?: ApiStudentUnsubscribeCourseDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiStudentUnsubscribeCourseDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentUnsubscribeCourseDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentUnsubscribeCourseDelete(params?: ApiStudentUnsubscribeCourseDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiStudentUnsubscribeCourseDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiStudentWhatchVideoPost()` */
  static readonly ApiStudentWhatchVideoPostPath = '/api/Student/WhatchVideo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentWhatchVideoPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentWhatchVideoPost$Response(params?: ApiStudentWhatchVideoPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiStudentWhatchVideoPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentWhatchVideoPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentWhatchVideoPost(params?: ApiStudentWhatchVideoPost$Params, context?: HttpContext): Observable<void> {
    return this.apiStudentWhatchVideoPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiStudentRequestCoursePost()` */
  static readonly ApiStudentRequestCoursePostPath = '/api/Student/requestCourse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentRequestCoursePost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentRequestCoursePost$Plain$Response(params?: ApiStudentRequestCoursePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return apiStudentRequestCoursePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentRequestCoursePost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentRequestCoursePost$Plain(params?: ApiStudentRequestCoursePost$Plain$Params, context?: HttpContext): Observable<string> {
    return this.apiStudentRequestCoursePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentRequestCoursePost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentRequestCoursePost$Response(params?: ApiStudentRequestCoursePost$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return apiStudentRequestCoursePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentRequestCoursePost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentRequestCoursePost(params?: ApiStudentRequestCoursePost$Params, context?: HttpContext): Observable<string> {
    return this.apiStudentRequestCoursePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiStudentGet()` */
  static readonly ApiStudentGetPath = '/api/Student';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGet$Plain$Response(params?: ApiStudentGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentDtoIEnumerablePagination>> {
    return apiStudentGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGet$Plain(params?: ApiStudentGet$Plain$Params, context?: HttpContext): Observable<ViewStudentDtoIEnumerablePagination> {
    return this.apiStudentGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentDtoIEnumerablePagination>): ViewStudentDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGet$Response(params?: ApiStudentGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentDtoIEnumerablePagination>> {
    return apiStudentGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentGet(params?: ApiStudentGet$Params, context?: HttpContext): Observable<ViewStudentDtoIEnumerablePagination> {
    return this.apiStudentGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentDtoIEnumerablePagination>): ViewStudentDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiStudentPut()` */
  static readonly ApiStudentPutPath = '/api/Student';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentPut$Response(params?: ApiStudentPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiStudentPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentPut(params?: ApiStudentPut$Params, context?: HttpContext): Observable<void> {
    return this.apiStudentPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiStudentIdGet()` */
  static readonly ApiStudentIdGetPath = '/api/Student/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentIdGet$Plain$Response(params: ApiStudentIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentDto>> {
    return apiStudentIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentIdGet$Plain(params: ApiStudentIdGet$Plain$Params, context?: HttpContext): Observable<ViewStudentDto> {
    return this.apiStudentIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentDto>): ViewStudentDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentIdGet$Response(params: ApiStudentIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewStudentDto>> {
    return apiStudentIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentIdGet(params: ApiStudentIdGet$Params, context?: HttpContext): Observable<ViewStudentDto> {
    return this.apiStudentIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewStudentDto>): ViewStudentDto => r.body)
    );
  }

  /** Path part for operation `apiStudentIdDelete()` */
  static readonly ApiStudentIdDeletePath = '/api/Student/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentIdDelete$Response(params: ApiStudentIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiStudentIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStudentIdDelete(params: ApiStudentIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiStudentIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiStudentAddStudentPost()` */
  static readonly ApiStudentAddStudentPostPath = '/api/Student/addStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentAddStudentPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentAddStudentPost$Response(params?: ApiStudentAddStudentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiStudentAddStudentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentAddStudentPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentAddStudentPost(params?: ApiStudentAddStudentPost$Params, context?: HttpContext): Observable<void> {
    return this.apiStudentAddStudentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
