/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiLevelYearsChangeStatusPut } from '../fn/level-years/api-level-years-change-status-put';
import { ApiLevelYearsChangeStatusPut$Params } from '../fn/level-years/api-level-years-change-status-put';
import { apiLevelYearsGetAllLevelYearsByLevelIdGet } from '../fn/level-years/api-level-years-get-all-level-years-by-level-id-get';
import { ApiLevelYearsGetAllLevelYearsByLevelIdGet$Params } from '../fn/level-years/api-level-years-get-all-level-years-by-level-id-get';
import { apiLevelYearsGetAllLevelYearsByLevelIdGet$Plain } from '../fn/level-years/api-level-years-get-all-level-years-by-level-id-get-plain';
import { ApiLevelYearsGetAllLevelYearsByLevelIdGet$Plain$Params } from '../fn/level-years/api-level-years-get-all-level-years-by-level-id-get-plain';
import { apiLevelYearsGetAllLevelYearsGet } from '../fn/level-years/api-level-years-get-all-level-years-get';
import { ApiLevelYearsGetAllLevelYearsGet$Params } from '../fn/level-years/api-level-years-get-all-level-years-get';
import { apiLevelYearsGetAllLevelYearsGet$Plain } from '../fn/level-years/api-level-years-get-all-level-years-get-plain';
import { ApiLevelYearsGetAllLevelYearsGet$Plain$Params } from '../fn/level-years/api-level-years-get-all-level-years-get-plain';
import { apiLevelYearsIdDelete } from '../fn/level-years/api-level-years-id-delete';
import { ApiLevelYearsIdDelete$Params } from '../fn/level-years/api-level-years-id-delete';
import { apiLevelYearsIdGet } from '../fn/level-years/api-level-years-id-get';
import { ApiLevelYearsIdGet$Params } from '../fn/level-years/api-level-years-id-get';
import { apiLevelYearsIdGet$Plain } from '../fn/level-years/api-level-years-id-get-plain';
import { ApiLevelYearsIdGet$Plain$Params } from '../fn/level-years/api-level-years-id-get-plain';
import { apiLevelYearsIdPut } from '../fn/level-years/api-level-years-id-put';
import { ApiLevelYearsIdPut$Params } from '../fn/level-years/api-level-years-id-put';
import { apiLevelYearsPost } from '../fn/level-years/api-level-years-post';
import { ApiLevelYearsPost$Params } from '../fn/level-years/api-level-years-post';
import { ViewLevelYearsDto } from '../models/view-level-years-dto';
import { ViewLevelYearsDtoIEnumerablePagination } from '../models/view-level-years-dto-i-enumerable-pagination';

@Injectable({ providedIn: 'root' })
export class LevelYearsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiLevelYearsGetAllLevelYearsGet()` */
  static readonly ApiLevelYearsGetAllLevelYearsGetPath = '/api/LevelYears/getAllLevelYears';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLevelYearsGetAllLevelYearsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelYearsGetAllLevelYearsGet$Plain$Response(params?: ApiLevelYearsGetAllLevelYearsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewLevelYearsDtoIEnumerablePagination>> {
    return apiLevelYearsGetAllLevelYearsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLevelYearsGetAllLevelYearsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelYearsGetAllLevelYearsGet$Plain(params?: ApiLevelYearsGetAllLevelYearsGet$Plain$Params, context?: HttpContext): Observable<ViewLevelYearsDtoIEnumerablePagination> {
    return this.apiLevelYearsGetAllLevelYearsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewLevelYearsDtoIEnumerablePagination>): ViewLevelYearsDtoIEnumerablePagination => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLevelYearsGetAllLevelYearsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelYearsGetAllLevelYearsGet$Response(params?: ApiLevelYearsGetAllLevelYearsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewLevelYearsDtoIEnumerablePagination>> {
    return apiLevelYearsGetAllLevelYearsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLevelYearsGetAllLevelYearsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelYearsGetAllLevelYearsGet(params?: ApiLevelYearsGetAllLevelYearsGet$Params, context?: HttpContext): Observable<ViewLevelYearsDtoIEnumerablePagination> {
    return this.apiLevelYearsGetAllLevelYearsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewLevelYearsDtoIEnumerablePagination>): ViewLevelYearsDtoIEnumerablePagination => r.body)
    );
  }

  /** Path part for operation `apiLevelYearsGetAllLevelYearsByLevelIdGet()` */
  static readonly ApiLevelYearsGetAllLevelYearsByLevelIdGetPath = '/api/LevelYears/getAllLevelYearsByLevelId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLevelYearsGetAllLevelYearsByLevelIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelYearsGetAllLevelYearsByLevelIdGet$Plain$Response(params?: ApiLevelYearsGetAllLevelYearsByLevelIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewLevelYearsDto>>> {
    return apiLevelYearsGetAllLevelYearsByLevelIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLevelYearsGetAllLevelYearsByLevelIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelYearsGetAllLevelYearsByLevelIdGet$Plain(params?: ApiLevelYearsGetAllLevelYearsByLevelIdGet$Plain$Params, context?: HttpContext): Observable<Array<ViewLevelYearsDto>> {
    return this.apiLevelYearsGetAllLevelYearsByLevelIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewLevelYearsDto>>): Array<ViewLevelYearsDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLevelYearsGetAllLevelYearsByLevelIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelYearsGetAllLevelYearsByLevelIdGet$Response(params?: ApiLevelYearsGetAllLevelYearsByLevelIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ViewLevelYearsDto>>> {
    return apiLevelYearsGetAllLevelYearsByLevelIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLevelYearsGetAllLevelYearsByLevelIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelYearsGetAllLevelYearsByLevelIdGet(params?: ApiLevelYearsGetAllLevelYearsByLevelIdGet$Params, context?: HttpContext): Observable<Array<ViewLevelYearsDto>> {
    return this.apiLevelYearsGetAllLevelYearsByLevelIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ViewLevelYearsDto>>): Array<ViewLevelYearsDto> => r.body)
    );
  }

  /** Path part for operation `apiLevelYearsChangeStatusPut()` */
  static readonly ApiLevelYearsChangeStatusPutPath = '/api/LevelYears/ChangeStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLevelYearsChangeStatusPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelYearsChangeStatusPut$Response(params?: ApiLevelYearsChangeStatusPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiLevelYearsChangeStatusPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLevelYearsChangeStatusPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelYearsChangeStatusPut(params?: ApiLevelYearsChangeStatusPut$Params, context?: HttpContext): Observable<void> {
    return this.apiLevelYearsChangeStatusPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiLevelYearsIdGet()` */
  static readonly ApiLevelYearsIdGetPath = '/api/LevelYears/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLevelYearsIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelYearsIdGet$Plain$Response(params: ApiLevelYearsIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewLevelYearsDto>> {
    return apiLevelYearsIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLevelYearsIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelYearsIdGet$Plain(params: ApiLevelYearsIdGet$Plain$Params, context?: HttpContext): Observable<ViewLevelYearsDto> {
    return this.apiLevelYearsIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewLevelYearsDto>): ViewLevelYearsDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLevelYearsIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelYearsIdGet$Response(params: ApiLevelYearsIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ViewLevelYearsDto>> {
    return apiLevelYearsIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLevelYearsIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelYearsIdGet(params: ApiLevelYearsIdGet$Params, context?: HttpContext): Observable<ViewLevelYearsDto> {
    return this.apiLevelYearsIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewLevelYearsDto>): ViewLevelYearsDto => r.body)
    );
  }

  /** Path part for operation `apiLevelYearsIdPut()` */
  static readonly ApiLevelYearsIdPutPath = '/api/LevelYears/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLevelYearsIdPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLevelYearsIdPut$Response(params: ApiLevelYearsIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiLevelYearsIdPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLevelYearsIdPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLevelYearsIdPut(params: ApiLevelYearsIdPut$Params, context?: HttpContext): Observable<void> {
    return this.apiLevelYearsIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiLevelYearsIdDelete()` */
  static readonly ApiLevelYearsIdDeletePath = '/api/LevelYears/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLevelYearsIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelYearsIdDelete$Response(params: ApiLevelYearsIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiLevelYearsIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLevelYearsIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLevelYearsIdDelete(params: ApiLevelYearsIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiLevelYearsIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiLevelYearsPost()` */
  static readonly ApiLevelYearsPostPath = '/api/LevelYears';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLevelYearsPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLevelYearsPost$Response(params?: ApiLevelYearsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiLevelYearsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLevelYearsPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLevelYearsPost(params?: ApiLevelYearsPost$Params, context?: HttpContext): Observable<void> {
    return this.apiLevelYearsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
